@import 'variables.scss';
@import 'mixins.scss';

/*===============================================================
# Dashboard
===============================================================*/
.dashboard {
    display: flex;
    justify-content: flex-end;
}

.dashboard-app {
    width: 75%;
    padding: 2%;

    h3 {
        text-align: center;
        font-size: 2vw;
        text-transform: capitalize
    }


}

/*===============================================================
# Login
===============================================================*/
.admin-login {
    $color_1: #7875B5;
    $color_2: #4C489D;
    $color_3: #fff;
    $border-bottom-color_1: $insur-black;
    background: $insur-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 60%;
        object-fit: cover;
        height: 100vh;
    }


    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80vh;
        // padding: 5% 0;
        width: 30%;
    }

    .screen {
        // background: linear-gradient(90deg, #5D54A4, #7C78B8);
        background: $insur-extra;
        position: relative;
        height: 500px;
        width: 400px;
        box-shadow: 0px 0px 24px $insur-bg;
        margin-left: auto;
    }

    .screen__content {
        z-index: 1;
        position: relative;
        height: 100%;
    }

    .screen__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
        overflow: hidden;
    }

    .screen__background__shape {
        transform: rotate(45deg);
        position: absolute;
    }

    .screen__background__shape1 {
        height: 520px;
        width: 520px;
        background: #FFF;
        top: -50px;
        right: 120px;
        border-radius: 0 72px 0 0;
        border: 1px solid $insur-base;
    }

    .screen__background__shape2 {
        height: 220px;
        width: 220px;
        background: $insur-black;
        top: -172px;
        right: 0;
        border-radius: 32px;
    }

    .screen__background__shape3 {
        height: 540px;
        width: 190px;
        background: $background-gradient;
        top: -24px;
        right: 0;
        border-radius: 32px;
    }


    .login {
        width: 100%;
        padding: 30px;
        padding-top: 20%;
        display: flex;
        flex-direction: column;

        // img {
        //     width: 70px;
        //     padding-bottom: 10%;
        //     margin-left: -10%;
        // }

        h2 {
            font-weight: bolder;
            text-transform: uppercase;
            color: $insur-black;
            text-shadow: 2px 2px 2px rgba($color: #000, $alpha: 0.3);
            font-size: 25px;
            margin-bottom: 10%;
        }
    }

    .login__field {
        padding: 20px 0px;
        position: relative;

        svg {
            position: absolute;
            top: 30px;
            width: 20px;
            height: 20px;
            fill: $insur-base;
        }
    }



    .login__input {
        border: none;
        border-bottom: 2px solid $insur-primary;
        background: none;
        padding: 10px;
        padding-left: 10%;
        font-weight: 500;
        width: 75%;
        transition: .2s;

        &:active {
            outline: none;
            border-bottom-color: $border-bottom-color_1;
        }

        &:focus {
            outline: none;
            border-bottom-color: $border-bottom-color_1;
        }

        &:hover {
            outline: none;
            border-bottom-color: $border-bottom-color_1;
        }
    }

    .login__submit {
        background: #fff;
        font-size: 14px;
        margin-top: 30px;
        padding: 16px 20px;
        border-radius: 26px;
        border: 1px solid $insur-base;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        align-items: center;
        width: 100%;
        color: $insur-base;
        box-shadow: 0px 2px 2px $insur-base;
        cursor: pointer;
        transition: .2s;

        svg {
            width: 20px;
            height: 20px;
            margin-left: auto;
            fill: $insur-base;
        }

        &:active {
            border-color: $insur-black;
            outline: none;
        }

        &:focus {
            border-color: $insur-black;
            outline: none;
        }

        &:hover {
            border-color: $insur-black;
            outline: none;
            background: $insur-black;
            box-shadow: 0px 2px 2px rgba($color: #000, $alpha: 0.3);
            color: #fff;
            transition: .5s all ease;
            border: 1px solid #fff;

            svg {
                fill: #fff;
            }

        }
    }

    .button__icon {
        font-size: 24px;
        margin-left: auto;
        color: $color_1;
    }

    .social-login {
        position: absolute;
        height: 140px;
        width: 160px;
        text-align: center;
        bottom: 0px;
        right: 0px;
        color: $color_3;
    }

    .social-icons {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .social-login__icon {
        padding: 20px 10px;
        color: $color_3;
        text-decoration: none;
        text-shadow: 0px 0px 8px #7875B5;

        &:hover {
            transform: scale(1.5);
        }
    }
}

/*===============================================================
# Admin Dashbaord
===============================================================*/
.dashboard-nav {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 25%;
    background: $background-gradient;
    color: #ffff;
    padding: 2%;


    .a-logo {
        background: #fff;
        display: block;
        margin-bottom: 5%;
        padding: 8%;
        border-radius: 5px;

        img {
            width: 100%;
        }
    }

    .dashboard-nav-list {
        @include flex;
        flex-direction: column;
        margin-top: 30%;
        overflow-y: auto;

        .dashboard-nav-item {
            margin-bottom: 5%;
            font-size: 1.4vw;
            text-transform: capitalize;
            padding: 1%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #fff;

            svg {
                width: 15px;
                height: 15px;
                margin-right: 5%;
                fill: #fff;
            }

            &:hover {
                color: rgba($color: #fff, $alpha: 0.7);

                svg {
                    transition: .5s all ease;
                    fill: rgba($color: #fff, $alpha: 0.7);
                }
            }
        }
    }

    .admin-blog {
        margin-bottom: 5%;
        font-size: 1.4vw;
        text-transform: capitalize;
        padding: 1%;
        color: #fff;

        svg {
            width: 15px;
            height: 15px;
            margin-right: 5%;
            fill: #fff;
        }


        &:hover {
            .admin-blog-nav {
                display: flex;
                transform: 1s all ease;
            }
        }

        .admin-blog-nav {
            @include flex;
            flex-direction: column;
            color: #fff;
            margin-top: 5%;
            padding-top: 5%;
            padding-left: 5%;
            border-top: 1px solid #fff;
            height: 100%;
            display: none;

            svg {
                width: 12px;
                height: 12px;
                margin-right: 5%;
                fill: #fff;
            }

            a {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 2%;
                margin: 0;
                font-size: 1.3vw;
                color: #fff;

                &:hover {
                    // transform: scale(1.1) translateX(15px);
                    transition: .5s all ease;
                    color: rgba($color: #fff, $alpha: 0.6);
                    // background: transparent;

                    svg {
                        fill: rgba($color: #fff, $alpha: 0.6);
                        transition: .5s all ease;
                    }
                }
            }


        }
    }

    button {
        width: 90%;
        margin: 0 5%;
        border: none;
        border-top: 1px solid #fff;
        padding: 5%;
        position: absolute;
        bottom: 0;
        right: 0;
        background: transparent;
        color: #fff;
        font-size: 1.5vw;

        svg {
            width: 20px;
            height: 20px;
            margin-left: 5%;
            fill: #fff;
        }
    }
}

/*===============================================================
# Blog- Create
===============================================================*/
.blog-create {
    img {
        width: 100%;
        object-fit: contain;
    }

    form {
        display: flex;
        flex-direction: column;
        margin: 5% 0;
        // box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        padding: 2%;

        .__input {
            display: flex;
            justify-content: space-between;

            label {
                font-size: 1.2vw;
                text-transform: capitalize;
                width: 30%;
            }

            .b__input {
                width: 100%;

                span {
                    font-size: 1vw;
                    color: red;
                }
            }
        }

        input,
        textarea {
            font-size: 1vw;
            border: none;
            padding: 1% 2%;
            border-radius: 5px;
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            outline: none;
            color: #000;
            width: 100%;
            margin-bottom: 2%;
        }

        button {
            background: $background-gradient;
            color: #fff;
            border: none;
            font-size: 1.1vw;
            padding: 1%;
            border-radius: 5px;
            margin-top: 10%;
            width: 100%;
        }
    }


}

/*===============================================================
# Blog- List
===============================================================*/
.blog-list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 5% 0;

    .blog-card {
        width: calc(100% / 3.2);
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.2);
        margin: 1%;

        position: relative;

        img {
            width: 100%;
        }


        .blog-card-text {
            margin-bottom: 20%;
            padding: 5%;

            p {
                font-size: 1.1vw;
                font-weight: bold;
                margin-bottom: 1%;
            }

            span {
                font-size: .9vw;
                color: $insur-base;

                svg {
                    width: 10px;
                    height: 10px;
                    fill: $insur-base;
                    margin-right: 5%;
                }
            }
        }

        .blog-card-button {
            display: flex;
            justify-content: center;
            align-items: stretch;
            position: absolute;
            bottom: 5%;
            left: 0;
            width: 100%;

            a,
            button {
                width: calc(100% / 2.2);
                border: none;
                padding: 2%;
                border-radius: 5px;
                font-size: 1vw;
                text-transform: capitalize;
                text-align: center;
                margin: 1%;
                background: $background-gradient;
                color: #fff;

                &:hover {
                    background: transparent;
                    color: #000;
                    transition: .2s all ease-in-out;
                    border: 1px solid $insur-base;
                }
            }
        }
    }

    p {
        font-size: 1.2vw;
    }
}

/*===============================================================
# Forms DB
===============================================================*/
.contact-form {
    table {
        width: 100%;
        border-collapse: collapse;
        margin: 50px auto;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
        background: #eee;
    }

    th {
        background: $insur-base;
        color: white;
        font-weight: bold;
    }

    td,
    th {
        padding: 1%;
        border: 1px solid #ccc;
        text-align: left;
        font-size: 1.1vw;
        word-break: break-all;
    }

    td {
        background: #fff;
        width: calc(100% /6);
        font-size: .9vw;
    }

    .letter {
        overflow-y: auto;
        height: 90px;
        width: 100%;
        display: block;
    }

    /* 
    Max width before this PARTICULAR table gets nasty
    This query will take effect for any screen smaller than 760px
    and also iPads specifically.
    */
    @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {

        table {
            width: 100%;
        }

        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            /* Label the data */
            content: attr(data-column);

            color: #000;
            font-weight: bold;
        }

    }
}

/*===============================================================
# Reset Password
===============================================================*/
.admin-settings {
    display: flex;
    flex-direction: column;
    margin: 10% auto 0 auto;
    width: 80%;


    input {
        font-size: 1.2vw;
        border: none;
        padding: 1% 2%;
        border-radius: 5px;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        outline: none;
        color: #000;
        width: 100%;
        margin-bottom: 1%;
    }

    button {
        background: $background-gradient;
        color: #fff;
        border: none;
        font-size: 1.3vw;
        padding: 1%;
        border-radius: 5px;
        margin-top: 1%;
        width: 100%;
    }
}

@media (min-width: 200px) and (max-width: 768px) {
    .dashboard {
        display: none;
    }

    .admin-login {
        display: none;
    }
}