:root {
    --insur-black: #16243d;
}

.bot-btn .chatbot {
    /* background: rgba(0, 0, 0, 0.87); */
    border: none;
    cursor: pointer;
    /* padding: 1em; */
    filter: drop-shadow(17px 10px 10px rgba(0, 0, 0, 0.4));
    border-radius: 50%;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    object-fit: cover;
    width: 65%;
}

.bot-btn {
    position: fixed;
    bottom: 2%;
    right: -1%;
    width: 120px;
    z-index: 1000;
}

.bot-shape {
    position: fixed;
    width: 15%;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.bot {
    position: fixed;
    bottom: 2%;
    right: 1%;
    border: 2px solid lightgray;
    z-index: 1000;
    /* border-radius: 7px; */
}

.bot-msg {
    position: relative;
}

.bot-close-btn {
    position: absolute;
    top: .5em;
    right: .5em;
    z-index: 1001;
    cursor: pointer;
}

.bot .react-chatbot-kit-chat-container {

    border-radius: 20px !important;
}

.bot-btn:hover {
    filter: drop-shadow(0px 37px 20px -15px rgba(0, 0, 0, 0.2));
    transform: translate(0px, -10px);
}

.bot {
    display: none;
}

/* 
.bot .react-chatbot-kit-chat-input-form {
    display: none;
} */

.bot .react-chatbot-kit-chat-inner-container {

    background-color: #fff;
}

/* 
.bot .react-chatbot-kit-chat-input-container {
  
    display: none;
}*/

.bot .react-chatbot-kit-chat-bot-avatar-letter {
    display: none;
}


@media (min-width: 320px) and (max-width: 780px){
    .bot-btn {
        width: 70px;
        right: -3%;
    }
}