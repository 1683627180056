@import '../../Sass/variables.scss';
@import '../../Sass/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fauna+One&display=swap');

/* ===================================================
   Home About
====================================================*/
.home-about {
    padding: $padding;

    .home-about-us {
        @include flex;
        margin: $margin;

        .abt-pic {
            width: 50%;
            margin-right: 5%;
            position: relative;

            img {
                width: 100%;
                object-fit: cover;
            }

            .img-1 {
                height: 30%;
                width: 20%;
                background: transparent;
                border-right: 5px solid $insur-base;
                border-bottom: 5px solid $insur-base;
                position: absolute;
                bottom: -4%;
                right: -2%;
                display: block;
                box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.3);
            }

            .img-2 {
                height: 30%;
                width: 20%;
                background: transparent;
                border-left: 5px solid $insur-base;
                border-top: 5px solid $insur-base;
                position: absolute;
                top: -4%;
                left: -2%;
                display: block;
                box-shadow: -2px -2px 2px rgba($color: #000000, $alpha: 0.3);
            }
        }

        .abt-text {
            width: 40%;

            h2 {
                font-weight: bolder;
                margin-bottom: 5%;
                font-size: 42px;
                // text-shadow: -1px -1px 1px rgba($color: $insur-base, $alpha: 1);
            }

            p {
                font-size: 16px;
                line-height: 170%;
                word-wrap: break-all;
            }
        }
    }
}

/* ===================================================
   Why Choose Us
====================================================*/
.whychooseus {
    padding: 5% 5% 0 5%;
    // background: $insur-extra;

    .whychooseus-content {
        @include flex;
        flex-wrap: wrap;
        margin: 5% 0;


        .whychooseus-content-text {
            width: calc(100% / 2.1);
            padding: 2%;
            margin: 1%;
            box-shadow: 2px 2px 5px rgba($color: $insur-base, $alpha: 0.2);
            position: relative;
            background: #fff;

            &:hover {
                transition: .5s all ease;
                background: $background-gradient;
                // background: $background-gradient;
                color: #fff;

                img {
                    transition: .5s all ease;
                    background: #fff;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 80px;
                height: 80px;
                padding: 2%;
                background: rgba($color: $insur-base, $alpha: 0.2);
                border-radius: 0 0 50% 0;
            }

            h6 {
                position: absolute;
                top: 5%;
                left: 35%;
                font-weight: bold;
                font-size: 2vw;
            }

            p {
                margin-top: 15%;
            }
        }
    }
}

/* ===================================================
   Services
====================================================*/
.services-section {
    margin-top: 5%;
    padding: 5%;
    background: $insur-extra;

    .services-content {

        margin-top: 5%;

        .alice-carousel__dots,
        .alice-carousel__prev-btn,
        .alice-carousel__next-btn {
            display: none;
        }

        .__target+li {
            transform: translateX(8px) scale(1.3);
            transition: .5s all ease;
            z-index: 3000;

            .services-content-text {
                box-shadow: 2px 2px 25px rgba($color: $insur-base, $alpha: 0.3);

                &:hover {
                    box-shadow: none;
                }
            }
        }


        li {
            padding: 5% 0;
        }

    }

    .services-content-text {
        margin: 1%;
        position: relative;
        // animation: drop-in 1s ease 200ms backwards;
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
        width: 85%;
        display: inline-block;
        background: transparent;

        &:hover {
            box-shadow: none;

            h3 {
                display: none;
            }

            a {
                z-index: 1001;
                transition: .5s all ease;
                animation: blur-in .5s 1;
            }
        }

        img {
            width: 100%;
            object-fit: cover;
        }


        h3 {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 60%;
            background: rgba($color: $insur-black, $alpha: 0.7);
            color: #fff;
            text-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.8);
            font-size: 23px;
            text-transform: uppercase;
            padding: 5%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            word-wrap: break-word;
            font-family: "Playfair Display", serif;
            line-height: 150%;

            &::after {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                content: "";
                height: 60%;
                width: 1px;
                background: #fff;
            }

        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            background: rgba($color: #000000, $alpha: 0.1);
            backdrop-filter: blur(5px);

            &::before {
                position: absolute;
                content: "Read More";
                top: 50%;
                border: 1px solid #fff;
                transform: translate(0, -50%);
                left: 50%;
                transform: translate(-50%, -50%);
                background: #000;
                border-radius: 10px;
                width: 50%;
                height: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5%;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                font-family: "Playfair Display", serif;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #fff;
                box-shadow: 2px 2px 5px rgba($color: #fff, $alpha: 0.3);
            }
        }

    }
}

#digital_marketing_services {
    .services-content {

        margin-top: 5%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .services-content-text {
        width: calc(100% / 3.2);
    }
}

/* ===================================================
Technologies
====================================================*/
.technologies {
    // background-color: var(--insur-extra);
    padding: 5% 0;

    .tech-img {
        @include flex;
        margin-top: 5%;

        img {
            width: 8%;
            margin-right: 1%;
            padding: 1%;
        }
    }
}

/* =============================================================
    Testimonials
/===============================================================*/
.testimonials {
    // background-color: $insur-extra;
    // background: rgba($color: $green, $alpha: 0.1);
    padding: 2em;
    background: $insur-extra;


    .testimonials-body {


        #mobile {
            display: none;

            .carousel .control-dots {
                display: none;
            }
        }


        .carousel.carousel-slider .control-arrow {
            height: 80%;

            &:hover {
                background: transparent;
            }
        }

        .carousel .control-prev.control-arrow:before {
            border-right: 8px solid $insur-black;
        }

        .carousel .control-next.control-arrow:before {
            border-left: 8px solid $insur-black;
        }

        .carousel .control-dots .dot {
            box-shadow: 1px 1px 5px $insur-black;
            border: 2px solid $insur-black;
            width: 15px;
            height: 15px;
        }

        .carousel {
            .carousel-wrapper {
                width: 100%;
                margin: auto;

                .carousel-body {
                    margin-bottom: 10%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: stretch;
                    animation: animateright 1s ease 200ms backwards;
                    padding: 3%;
                }
            }
        }

        figcaption {
            width: calc(100% / 2);
            position: relative;
            margin: 0 .5%;

            .snip-img {
                .profile {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background: #fff;
                    padding: 1%;
                    object-fit: contain;
                    position: absolute;
                    top: 0;
                    left: 5%;
                    border: 3px solid $insur-black;
                }
            }


            .snip {
                box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.3);
                border-radius: 5px;
                background: $background-gradient;
                padding: 5%;
                width: 100%;
                height: 60vh;
                margin-top: 5%;
                position: relative;
                color: #fff;

                .s {
                    background: #fff;
                    border-radius: 5px;
                    padding: 4% 2% 2% 5%;
                    position: relative;
                    bottom: -30%;
                    height: 70%;

                    // &:before {
                    //     font-family: 'FontAwesome';
                    //     content: "\201C";
                    //     position: absolute;
                    //     font-size: 30px;
                    //     opacity: 0.25;
                    //     color: #000;
                    //     font-style: normal;
                    //     top: 0;
                    //     left: 3%;
                    // }

                    .snip-text {
                        // margin-bottom: 15%;
                        font-size: .9vw;
                        text-align: left;
                        // padding-left: 5%;
                        color: #000;

                        p {
                            margin-bottom: 2%;
                        }

                        button {
                            //    border: 2px solid #fff;
                            border: none;
                            background: $background-gradient;
                            color: #fff;
                            padding: 1% 3%;
                            box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.4);
                            border-radius: 4px;
                        }
                    }
                }

                .snip-child {
                    text-align: right;
                    position: absolute;
                    top: 5%;
                    right: 5%;

                    h6,
                    h4 {
                        font-weight: 300;
                        margin: 1% 0;
                    }

                    h4 {
                        font-weight: bold;
                        font-size: 1.1vw;
                    }

                    h6 {
                        text-transform: capitalize;
                        color: rgba($color: #fff, $alpha: 0.6);
                        font-size: .9vw;
                        margin-top: 2%;

                    }

                }


            }
        }

    }
}

/* =============================================================
    Certificates
/===============================================================*/
.certificates {
    // background: $insur-extra;
    animation: 1s ease-out 0s 1 slideInLeft;
    padding: 5% 0;

    .cer-body {
        margin-top: 5%;

        .cer-body-img {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            margin: auto .5em;

            #Frames {
                margin: 5% 0;
                width: calc(100% / 2);
                margin: 3%;

                .Frame {
                    display: inline-block;
                    padding: 40px;
                    border-width: 20px;
                    border-style: solid;
                    border-color: #000 #1A2421 #000 #1A2421;
                    background: #f5f5f5;
                    box-shadow: inset 0 2px 5px rgba(0, 0, 0, .6), 0 5px 2px rgba(0, 0, 0, .1), 0 10px 20px rgba(0, 0, 0, .8);
                    position: relative;
                    overflow: hidden;
                    width: 90%;

                    img {
                        border-width: 2px;
                        border-style: solid;
                        border-color: #BBBAB4 #C7C7BF #E5E4DF #C7C7BF;
                        box-shadow: 0 -1px 1px rgba(0, 0, 0, .1), 0 1px 1px 1px rgba(255, 255, 255, .7);
                        width: 100%;
                    }
                }

            }

            #Frames,
            .Frame {
                list-style: none;
                list-style-type: none;
                margin: 0px;
                padding: 0px;
                text-align: center;
            }
        }
    }

}

@keyframes move {
    from {
        right: 10%;
        transform: translateX(2000%);
    }

    to {
        right: 100%;
        transform: translateX(-2000%);
    }
}

@keyframes animatedImage {
    0% {
        left: 0;
    }

    50% {
        left: 100%;
    }

    100% {
        left: 0;
    }
}

/* =============================================================
    Partners is Success
/===============================================================*/
.partners {
    padding: 5%;
    background: $insur-extra;

    .partners-body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2%;
        height: 150%;
        margin: auto;
        overflow: hidden;

        img {
            width: 15%;
            // height: 150px;
            object-fit: contain;
            margin: 1% 5% 1% 2%;
            filter: drop-shadow(2px 2px 5px rgba($color: #000000, $alpha: 0.1));
            animation: move 40s linear infinite -1.5s;
            // transform: translate(300%);
            margin-left: auto;

            &:hover {
                transform: scale(1.4);
                transition: .5s all ease;
            }

            &:nth-child(3) {
                border-radius: 50%;
            }
        }
    }



}


/* ===================================================
Home-Blogs
====================================================*/
.home-blog {
    padding: 5%;
    // background: var(--insur-extra);
    background: $insur-extra;

    #mobile {
        display: none;
    }

    .carousel .control-dots .dot {
        display: none;
    }

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid $green;
        margin-right: -90%;
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid $green;
        margin-left: -90%;
    }



    .home-blog-card {
        width: 90%;
        margin: 0 5%;
        height: 100%;
        // background: $background-gradient;
        background: $background-gradient;
        box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.3);
        border-radius: 10px;
        position: relative;

        img {
            width: 100%;
            height: 70%;
            object-fit: cover;
        }

        .home-blog-card-text {
            padding: 3% 5%;

            a {
                color: #fff;
            }
        }

        h6 {
            position: absolute;
            width: 38%;
            top: 16%;
            right: -8%;
            // background: $background-gradient;
            background: $background-gradient;
            box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.3);
            color: #fff;
            font-size: 1.2vw;
            padding: 1% 6%;
            border-radius: 0 5px 5px 0;
            rotate: 90deg;
        }
    }
}

/* ===================================================
   Media Queries
====================================================*/

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .services-section {

        .services-content {

            .__target+li {
                transform: translateX(0px) scale(1);
            }
        }

    }

    .home-about .home-about-us .abt-text h2 {
        font-size: 20px;
    }

    #digital_marketing_services {
        .services-content-text {
            width: 100%;

            h3 {
                font-size: 18px;
            }
        }
    }

    .bot-shape {
        width: 40%;
        right: -5%;
    }


    .section-head .title-shape {
        width: 25% !important;
    }

    .w {
        // left: 10%;
        width: 50%;
        // top: 50%;
        position: inherit;
        margin-left: 5%;
        margin-top: 5%;

        a {
            font-size: 3vw;
        }
    }

    .partners {

        .partners-body {

            img {
                width: 40%;
            }
        }
    }


    /*--------------------------------------------------------------
     Offer
  --------------------------------------------------------------*/
    .offer {
        height: 28%;
        top: 35%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
     Technologies
  --------------------------------------------------------------*/
    .technologies {
        .carousel {
            .carousel-wrapper {
                .carousel-body {
                    margin-bottom: 14%;
                }
            }

            .control-dots {
                .dot {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Digital
  --------------------------------------------------------------*/
    #digital {
        .digital-what {
            .digital-what-content {
                flex-wrap: wrap;
                padding: 5%;

                .digital-what-img {
                    width: 100%;
                    margin: 2%;

                    img {
                        border: none;
                    }
                }

                .digital-what-text {
                    width: 100%;

                    p {
                        font-size: 3.5vw;
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .digital-advantages {
            .digital-advantages-content {
                margin-top: 5%;

                .digital-advantages-content-img {
                    width: calc(100% / 2.3);
                    margin-bottom: 10%;

                    h2 {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .services-section {
        .services-content {
            margin: 5%;
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: 100%;
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 70vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 10vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 10vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Our Projects
  --------------------------------------------------------------*/

    .our-projects {

        .o-p-body {
            flex-direction: column;

            .o-p-left {
                width: 100%;
                margin-bottom: 5%;

                h5 {
                    font-size: 4vw;
                }

                .o-p-left-content {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: 100%;
                    padding: 0;

                    .o-p-card {
                        width: calc(100% / 2.1);
                        padding: .5%;
                        margin: 1% 1%;

                        img {
                            width: 30px;
                        }

                        h4 {
                            font-size: 2vw;
                        }
                    }
                }


            }

            .o-p-right {
                width: 95%;

                h5 {
                    font-size: 3vw;

                    a {
                        font-size: 2vw;
                    }
                }

                .o-p-right-card {
                    img {
                        height: 40vh;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Faq
  --------------------------------------------------------------*/
    .faq {
        .faq-body {
            padding: 1%;
            flex-wrap: wrap;

            .faq-accordion,
            .faq-form {
                width: 100%;
            }

            .faq-form {
                margin: 0;

                h2 {
                    font-size: 5vw;
                }

                h4 {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 50%;

                }
            }
        }

        .why-website {
            margin-top: 10%;

            .web-body-title {
                p {
                    margin-top: 5%;
                    font-size: 2vw;
                    width: 90%;
                }
            }

            .why-website-content {
                div {
                    width: 100%;

                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .web-body-title {
            p {
                font-size: 2vw;
                width: 90%;
            }
        }

        .web-why {
            .card {
                width: 90%;
            }

            .card-title {
                font-size: 4vw;
            }

            .card-subtitle {
                font-size: 3vw;
            }
        }

        .types-website {
            .types-website-content {
                .text {
                    font-size: 10vw;
                }

                .container {
                    width: 100%;
                }

                .overlay {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                }
            }
        }

        .web-process {
            padding: 0 1%;

            .web-process-img {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.6vw;
                }
            }

            .container {
                .pullUp {
                    padding: .3em;
                    border-radius: 0;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Bulk Marketing
  --------------------------------------------------------------*/

    .bulk-services {
        .web-why-card {
            .card {
                width: 90%;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;

            &:before {
                width: 80px;
            }
        }
    }

    .banner-hero {
        margin-top: 12%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 6vw;
    }

    /*--------------------------------------------------------------
    Header
  --------------------------------------------------------------*/
    .logo {
        max-width: 200%;
    }

    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: 2.5vw;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1em;
        letter-spacing: 1px;
    }

    .dropdown-menu {
        width: 110%;
    }

    .navdropdown-subheadings img {
        width: 20px;
    }

    /*--------------------------------------------------------------
    Slider
  --------------------------------------------------------------*/
    .slider {

        .carousel {
            width: 100%;
            // height: 25vh;
            flex-direction: column;

            .carousel-caption {
                top: 37%;

                p {
                    font-size: 2vw;
                    width: 100%;
                }
            }

            .carousel-wrapper {
                width: 100%;
            }
        }

        .slider-form {
            width: 100%;
            height: 100%;
            padding: 5%;

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
     About
  --------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    .about_us {
        margin-top: 10%;

        .about_us-content {
            flex-direction: column;

            img {
                width: 100%;
            }

            .abt-text {
                width: 100%;
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .vision {
            flex-direction: column;

            .vision-content {
                margin: 5% 0;
                padding: 5%;

                h4 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
     Blog
  --------------------------------------------------------------*/
    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Why Choose Us
  --------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 5vw;
                    top: 10%;
                }

                p {
                    margin-top: 25%;
                    font-size: 2.8vw;
                    padding: 0 3%;
                    text-align: right;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Services
  --------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
    Team
  --------------------------------------------------------------*/
    .leaders {
        .team-card {
            .team-card-img {
                img {
                    width: 100%;
                    height: 200px;
                }
            }

            svg {
                width: 30px;
                height: 30px;
            }

            .team-card-caption {
                h4 {
                    font-size: 3.4vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 130px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 130px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2.5vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Testimonials
  --------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            #desktop {
                display: none;
            }

            .carousel {
                .carousel-wrapper {
                    .carousel-body {
                        flex-direction: column;
                        padding: 0 10%;
                        margin-bottom: 15%;
                    }
                }
            }

            #mobile {
                display: block;

                figcaption {
                    .snip {
                        height: 50vh !important;
                    }
                }
            }


            figcaption {
                width: 100%;
                margin-top: 10%;

                .snip-img {
                    .profile {
                        width: 70px;
                        height: 70px;
                        top: -10%;
                        border: 3px solid $insur-black;
                    }
                }

                .snip {


                    &:after {
                        height: 80px;
                    }

                    .s {
                        .snip-text {
                            p {
                                font-size: 2.2vw;
                            }


                            button {
                                font-size: 2vw;
                            }
                        }
                    }

                    .snip-child {
                        h4 {
                            font-size: 3vw !important;
                        }

                        h6 {
                            font-size: 2.5vw !important;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Home-blog
  --------------------------------------------------------------*/
    .home-blog {

        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            min-width: 100% !important;
        }

        #desktop {
            display: none;
        }

        #mobile {
            display: block;
        }



        .home-blog-card {
            height: 100%;

            .home-blog-card-text {
                position: relative;
                padding: 10% 5%;

                a {
                    font-size: 3.2vw;
                    line-height: 130%;
                    position: absolute;
                    top: -10%;
                    width: 100%;
                    left: 1%;
                    padding: 5%;
                }
            }

            h6 {
                font-size: 3vw;
            }

            img {
                height: 80%;

            }
        }
    }

    /*--------------------------------------------------------------
    Certificates
  --------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    margin: 3%;
                    width: 100%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Career
  --------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }

            .careers-card {
                .ag-format-container {
                    .ag-courses_box {
                        padding: 0;

                        .ag-courses_item {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 90%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 100%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Smo
  --------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
            border-radius: 10px;
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            flex-direction: column;

            .card {
                width: 95%;
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {
        img {
            width: 100%;
        }

        .web-why-card {
            .card {
                width: 90%;
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 6vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Web Solutions
  --------------------------------------------------------------*/
    .web-solutions {
        .smo-what-content {
            flex-direction: column;
        }

        .smo-what {
            .smo-what-content-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .solutions-feature {
            .solutions-feature-body {
                .solutions-feature-card {
                    width: 100%;
                    margin-bottom: 5%;

                    .caption {
                        h3 {
                            font-size: 4vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Footer
  --------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-2 {
                ul {
                    &:nth-child(3) {
                        flex-wrap: wrap;

                        li {
                            width: 30px;
                            height: 30px;
                            margin-right: 4%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .services-section {

        .services-content {

            .__target+li {
                transform: translateX(0px) scale(1);
            }

            .services-content-text {
                h3 {
                    font-size: 17px;
                }
            }

        }

    }

    #digital_marketing_services {
        .services-content-text {
            width: 100%;

            h3 {
                font-size: 20px;
            }
        }
    }


    .partners {

        .partners-body {

            img {
                width: 40%;
            }
        }
    }

    .bot-shape {
        width: 40%;
        right: -5%;
    }

    .section-head .title-shape {
        width: 20% !important;
        left: 40%;
        top: 120%;
    }

    .slider {
        .carousel {
            .carousel-caption {
                width: 80%;
                left: 45%;
            }
        }
    }

    .w {
        // left: 10%;
        width: 40%;
        // top: 50%;
        position: inherit;
        margin-left: 5%;
        margin-top: 5%;

        a {
            font-size: 2vw;
        }
    }

    /*--------------------------------------------------------------
     Offer
  --------------------------------------------------------------*/
    .offer {
        height: 28%;
        top: 35%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
   Digital
  --------------------------------------------------------------*/
    #digital {
        .digital-what {
            .digital-what-content {
                flex-wrap: wrap;
                padding: 5%;

                .digital-what-img {
                    width: 100%;
                    margin: 2%;

                    img {
                        border: none;
                    }
                }

                .digital-what-text {
                    width: 100%;

                    p {
                        font-size: 3.5vw;
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .digital-advantages {
            .digital-advantages-content {
                margin-top: 5%;

                .digital-advantages-content-img {
                    width: calc(100% / 2.3);
                    margin-bottom: 10%;

                    h2 {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .services-section {
        .services-content {
            margin: 5%;
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: 100%;
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 70vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 10vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 10vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Our Projects
  --------------------------------------------------------------*/
    .our-projects {

        .o-p-body {
            flex-direction: column;

            .o-p-left {
                width: 100%;
                margin-bottom: 5%;

                h5 {
                    font-size: 4vw;
                }

                .o-p-left-content {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: 100%;
                    padding: 0;

                    .o-p-card {
                        width: calc(100% / 2.1);
                        padding: .5%;
                        margin: 1% 1%;

                        img {
                            width: 30px;
                        }

                        h4 {
                            font-size: 2vw;
                        }

                    }
                }


            }

            .o-p-right {
                width: 95%;

                h5 {
                    font-size: 3vw;

                    a {
                        font-size: 2vw;
                    }
                }

                .o-p-right-card {
                    img {
                        height: 50vh;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Faq
--------------------------------------------------------------*/
    .faq {
        .faq-body {
            padding: 1%;
            flex-wrap: wrap;

            .faq-accordion,
            .faq-form {
                width: 100%;
            }

            .faq-form {
                margin: 0;

                h2 {
                    font-size: 5vw;
                }

                h4 {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Web
--------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

        .why-website {
            margin-top: 10%;

            .web-body-title {
                p {
                    margin-top: 5%;
                    font-size: 2vw;
                    width: 90%;
                }
            }

            .why-website-content {
                div {

                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .web-body-title {
            p {
                font-size: 2vw;
                width: 90%;
            }
        }

        .web-why {
            .card {
                width: 90%;
            }

            .card-title {
                font-size: 4vw;
            }

            .card-subtitle {
                font-size: 3vw;
            }
        }

        .types-website {
            .types-website-content {
                .text {
                    font-size: 10vw;
                }

                .container {
                    width: 100%;
                }

                .overlay {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                }
            }
        }

        .web-process {
            padding: 0 1%;

            .web-process-img {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.6vw;
                }
            }

            .container {
                .pullUp {
                    padding: .3em;
                    border-radius: 0;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Bulk marketing
--------------------------------------------------------------*/

    .bulk-services {
        .web-why-card {
            .card {
                width: 90%;

                .card-title {
                    font-size: 4vw;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Content marketing
--------------------------------------------------------------*/
    .content-services {
        img {
            width: 100%;
        }

        .web-why-card {
            .card {
                width: 90%;
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 5vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }


    /*--------------------------------------------------------------
Common
--------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }

    .banner-hero {
        margin-top: 8%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 6vw;
    }

    /*--------------------------------------------------------------
Navbar
--------------------------------------------------------------*/
    .logo {
        max-width: 200%;
    }

    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: .9em;
        font-weight: 600 !important;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1.2em;
        letter-spacing: 1px;
        padding: 10% 5%;
    }

    .dropdown-menu {
        width: 110%;
    }


    /*--------------------------------------------------------------
Slider
--------------------------------------------------------------*/
    .slider {

        .carousel {
            width: 100%;
            // height: 25vh;
            flex-direction: column;

            .carousel-wrapper {
                width: 100%;
            }

            .carousel-caption {
                top: 35%;

                h1 {
                    font-size: 8vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }

        .slider-form {
            width: 100%;
            height: 100%;
            padding: 5%;

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
About
--------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    .about_us {
        margin-top: 10%;

        .about_us-content {
            flex-direction: column;

            img {
                width: 100%;
            }

            .abt-text {
                width: 100%;
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .vision {
            flex-direction: column;

            .vision-content {
                margin: 5% 0;
                padding: 5%;

                h4 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
 Blog
--------------------------------------------------------------*/
    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 5vw;
                    top: 10%;
                }

                p {
                    margin-top: 25%;
                    font-size: 2.8vw;
                    padding: 0 3%;
                    text-align: right;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Services
--------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
Team
--------------------------------------------------------------*/
    .leaders {
        .team-card {
            .team-card-img {
                img {
                    width: 100%;
                    height: 250px;
                }
            }

            svg {
                width: 30px;
                height: 30px;
            }

            .team-card-caption {
                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 130px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 130px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Testimonials
--------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            .carousel {
                .carousel-wrapper {
                    .carousel-body {
                        flex-direction: column;
                        padding: 0 10%;
                        margin-bottom: 15%;
                    }
                }
            }

            #desktop {
                display: none;
            }

            #mobile {
                display: block;

                figcaption {
                    .snip {
                        height: 70vh;
                    }
                }
            }

            figcaption {
                width: 100%;
                margin-top: 10%;

                .snip-img {
                    .profile {
                        top: -5%;
                        left: 6%;
                        border: 3px solid $insur-black;
                    }
                }

                .snip {
                    height: 60vh;

                    &:after {
                        height: 120px;
                    }

                    .s {
                        .snip-text {
                            font-size: 2.2vw;

                            button {
                                font-size: 2vw;
                                display: none;
                            }
                        }
                    }

                    .snip-child {
                        h4 {
                            font-size: 2.6vw;
                        }

                        h6 {
                            font-size: 1.8vw;
                        }
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
Home-blog
--------------------------------------------------------------*/
    .home-blog {

        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            min-width: 100% !important;
        }

        #desktop {
            display: none;
        }

        #mobile {
            display: block;
        }



        .home-blog-card {
            height: 100%;

            .home-blog-card-text {
                position: relative;
                padding: 10% 5%;

                a {
                    font-size: 3.2vw;
                    line-height: 130%;
                    position: absolute;
                    top: -10%;
                    width: 100%;
                    left: 1%;
                    padding: 5%;
                }
            }

            h6 {
                font-size: 3vw;
            }

            img {
                height: 80%;

            }
        }
    }

    /*--------------------------------------------------------------
Certificates
--------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    width: 100%;
                    margin: 3%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Career
--------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }

            .careers-card {
                .ag-format-container {
                    .ag-courses_box {
                        padding: 0;

                        .ag-courses_item {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 90%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 100%;

                    .pin {
                        top: 5%;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Smo
--------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Seo
--------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
        }
    }

    /*--------------------------------------------------------------
PPC
--------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            flex-direction: column;

            .card {
                width: 95%;
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Web Solutions
  --------------------------------------------------------------*/
    .web-solutions {
        .smo-what-content {
            flex-direction: column;
        }

        .smo-what {
            .smo-what-content-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .solutions-feature {
            .solutions-feature-body {
                .solutions-feature-card {
                    width: 48%;
                    margin-bottom: 5%;

                    .caption {
                        h3 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    .services-section {

        .services-content {

            .__target+li {
                transform: translateX(0px) scale(1);
            }

            .services-content-text {
                h3 {
                    font-size: 17px;
                }
            }

        }

    }

    .home-about .home-about-us .abt-text h2 {
        font-size: 30px;
    }

    #digital_marketing_services {
        .services-content-text {
            width: 100%;

            h3 {
                font-size: 20px;
            }
        }
    }


    .bot-shape {
        width: 40%;
        right: -5%;
    }

    .section-head .title-shape {
        width: 20% !important;
        left: 40%;
        top: 120%;
    }

    .slider {
        .carousel {
            .carousel-caption {
                width: 80%;
                left: 45%;
            }
        }
    }

    .w {
        // left: 10%;
        width: 60%;
        // top: 50%;
        position: inherit;
        margin-left: 5%;
        margin-top: 5%;

        a {
            font-size: 3vw;
        }
    }

    .partners {

        .partners-body {

            img {
                width: 40%;
            }
        }
    }

    /*--------------------------------------------------------------
     Offer
  --------------------------------------------------------------*/
    .offer {
        height: 28%;
        top: 35%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
   Digital
  --------------------------------------------------------------*/
    #digital {
        .digital-what {
            .digital-what-content {
                flex-wrap: wrap;
                padding: 5%;

                .digital-what-img {
                    width: 100%;
                    margin: 2%;

                    img {
                        border: none;
                    }
                }

                .digital-what-text {
                    width: 100%;

                    p {
                        font-size: 3.5vw;
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .digital-advantages {
            .digital-advantages-content {
                margin-top: 5%;

                .digital-advantages-content-img {
                    width: calc(100% / 2.3);
                    margin-bottom: 10%;

                    h2 {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .services-section {
        .services-content {
            margin: 5%;
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: 100%;
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 70vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 10vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 10vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Our Projects
  --------------------------------------------------------------*/

    .our-projects {

        .o-p-body {
            flex-direction: column;

            .o-p-left {
                width: 100%;
                margin-bottom: 5%;

                h5 {
                    font-size: 4vw;
                }

                .o-p-left-content {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: 100%;
                    padding: 0;

                    .o-p-card {
                        width: calc(100% / 2.1);
                        padding: .5%;
                        margin: 1% 1%;

                        img {
                            width: 30px;
                        }

                        h4 {
                            font-size: 2vw;
                        }

                    }
                }


            }

            .o-p-right {
                width: 95%;

                h5 {
                    font-size: 3vw;

                    a {
                        font-size: 2vw;
                    }
                }

                .o-p-right-card {
                    img {
                        height: 50vh;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    Faq
  --------------------------------------------------------------*/
    .faq {
        .faq-body {
            padding: 1%;
            flex-wrap: wrap;

            .faq-accordion,
            .faq-form {
                width: 100%;
            }

            .faq-form {
                margin: 0;

                h2 {
                    font-size: 5vw;
                }

                h4 {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

        .why-website {
            margin-top: 10%;

            .web-body-title {
                p {
                    margin-top: 5%;
                    font-size: 2vw;
                    width: 90%;
                }
            }

            .why-website-content {
                div {

                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .web-body-title {
            p {
                font-size: 2vw;
                width: 90%;
            }
        }

        .web-why {
            .card {
                width: 90%;
            }

            .card-title {
                font-size: 4vw;
            }

            .card-subtitle {
                font-size: 3vw;
            }
        }

        .types-website {
            .types-website-content {
                .text {
                    font-size: 10vw;
                }

                .container {
                    width: 100%;
                }

                .overlay {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                }
            }
        }

        .web-process {
            padding: 0 1%;

            .web-process-img {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.6vw;
                }
            }

            .container {
                .pullUp {
                    padding: .3em;
                    border-radius: 0;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Bulk marketing
  --------------------------------------------------------------*/

    .bulk-services {
        .web-why-card {
            .card {
                width: 90%;

                .card-title {
                    font-size: 4vw;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {
        img {
            width: 100%;
        }

        .web-why-card {
            .card {
                width: 90%;
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 5vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }


    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }

    .banner-hero {
        margin-top: 8%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 6vw;
    }

    /*--------------------------------------------------------------
    Navbar
  --------------------------------------------------------------*/
    .logo {
        max-width: 200%;
    }

    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: .9em;
        font-weight: 600 !important;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1.2em;
        letter-spacing: 1px;
        padding: 10% 5%;
    }

    .dropdown-menu {
        width: 110%;
    }


    /*--------------------------------------------------------------
Slider
--------------------------------------------------------------*/
    .slider {

        .carousel {
            width: 100%;
            // height: 25vh;
            flex-direction: column;

            .carousel-wrapper {
                width: 100%;
            }

            .carousel-caption {
                top: 35%;

                h1 {
                    font-size: 8vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }

        .slider-form {
            width: 100%;
            height: 100%;
            padding: 5%;

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
 About
--------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    .about_us {
        margin-top: 10%;

        .about_us-content {
            flex-direction: column;

            img {
                width: 100%;
            }

            .abt-text {
                width: 100%;
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .vision {
            flex-direction: column;

            .vision-content {
                margin: 5% 0;
                padding: 5%;

                h4 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
     Blog
  --------------------------------------------------------------*/
    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 5vw;
                    top: 10%;
                }

                p {
                    margin-top: 25%;
                    font-size: 2.8vw;
                    padding: 0 3%;
                    text-align: right;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Services
--------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
    Team
  --------------------------------------------------------------*/
    .leaders {
        .team-card {
            .team-card-img {
                img {
                    width: 100%;
                    height: 250px;
                }
            }

            svg {
                width: 30px;
                height: 30px;
            }

            .team-card-caption {
                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 130px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 130px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Testimonials
--------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            .carousel {
                .carousel-wrapper {
                    .carousel-body {
                        flex-direction: column;
                        padding: 0 10%;
                        margin-bottom: 15%;
                    }
                }
            }

            #desktop {
                display: none;
            }

            #mobile {
                display: block;

                figcaption {
                    .snip {
                        height: 70vh;
                    }
                }
            }

            figcaption {
                width: 100%;
                margin-top: 10%;

                .snip-img {
                    .profile {
                        top: -5%;
                        left: 6%;
                        border: 3px solid $insur-black;
                    }
                }

                .snip {
                    height: 40vh;

                    &:after {
                        height: 120px;
                    }

                    .s {
                        .snip-text {
                            font-size: 2.2vw;

                            button {
                                font-size: 2vw;
                                display: none;
                            }
                        }
                    }

                    .snip-child {
                        h4 {
                            font-size: 2.6vw;
                        }

                        h6 {
                            font-size: 1.8vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Home-blog
--------------------------------------------------------------*/
    .home-blog {

        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            min-width: 100% !important;
        }

        #desktop {
            display: none;
        }

        #mobile {
            display: block;
        }



        .home-blog-card {
            height: 100%;

            .home-blog-card-text {
                position: relative;
                padding: 10% 5%;

                a {
                    font-size: 3.2vw;
                    line-height: 130%;
                    position: absolute;
                    top: -10%;
                    width: 100%;
                    left: 1%;
                    padding: 5%;
                }
            }

            h6 {
                font-size: 3vw;
            }

            img {
                height: 80%;

            }
        }
    }

    /*--------------------------------------------------------------
Certificates
--------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    width: 100%;
                    margin: 3%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Career
  --------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }

            .careers-card {
                .ag-format-container {
                    .ag-courses_box {
                        padding: 0;

                        .ag-courses_item {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 90%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 100%;

                    .pin {
                        top: 5%;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Smo
  --------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            flex-direction: column;

            .card {
                width: 95%;
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Web Solutions
  --------------------------------------------------------------*/
    .web-solutions {
        .smo-what-content {
            flex-direction: column;
        }

        .smo-what {
            .smo-what-content-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .solutions-feature {
            .solutions-feature-body {
                .solutions-feature-card {
                    width: 100%;
                    margin-bottom: 5%;

                    .caption {
                        h3 {
                            font-size: 4vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-2 {
                ul {
                    &:nth-child(3) {
                        flex-wrap: wrap;

                        li {
                            width: 35px;
                            height: 35px;
                            margin-right: 2%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;

            }
        }
    }


}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {

    .home-about {

        .home-about-us {
            flex-direction: column;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;
            }
        }
    }

    #digital_marketing_services {
        .services-content-text {
            // width: calc(100%);

            h3 {
                font-size: 16px;
            }
        }
    }

    .partners {

        .partners-body {

            img {
                width: 30%;
            }
        }
    }


    .bot-shape {
        width: 25%;
        right: -5%;
    }

    .section-head .title-shape {
        width: 15% !important;
        left: 42%;
        top: 110%;
    }

    .slider {
        .carousel {
            .carousel-caption {
                width: 80%;
                left: 45%;
            }
        }
    }


    .our-projects {
        .o-p-body {
            .o-p-right {
                .o-p-right-card {
                    img {
                        height: 45vh;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

    }

    /*--------------------------------------------------------------
    Career
  --------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }


        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 60%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 50%;

                    .pin {
                        top: 5%;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {

        .web-why-card {
            .card {
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 3vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }

    .banner-hero {
        margin-top: 5%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 5vw;
    }

    /*--------------------------------------------------------------
    Navbar
  --------------------------------------------------------------*/
    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: .9em;
        font-weight: 600 !important;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1.2em;
        letter-spacing: 1px;
    }

    .dropdown-menu {
        width: 110%;
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {

                p {
                    margin-top: 25%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Team
  --------------------------------------------------------------*/
    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 200px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 200px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                    font-weight: bold;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    .leaders {
        .team-card {
            .team-card-caption {
                h4 {
                    font-size: 3vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2.3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        .seo-key-content-text {
            margin: 1%;
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            .card {
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }


}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

    .partners {

        .partners-body {

            img {
                width: 70px;
                height: 70px;
            }
        }
    }

    .offer {
        height: 48%;
        top: 30%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: calc(100% / 2.2);
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 100vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 20vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 20vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 6%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {

        .web-why-card {
            .card {
                margin: 1%;

                .card-title {
                    font-size: 2.5vw;
                }

                .card-subtitle {
                    font-size: 1.8vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            .card {
                p {
                    font-size: 1.5vw;
                }
            }
        }
    }


    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }


    /*--------------------------------------------------------------
Home About
--------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 3vw;
                    top: 10%;
                }

                p {
                    font-size: 2.4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Services
--------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;

                h3 {
                    font-size: 6vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Team
--------------------------------------------------------------*/
    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 200px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 200px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                    font-weight: bold;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    .leaders {
        .team-card {
            .team-card-caption {
                h4 {
                    font-size: 3vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2.3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Testimonials
--------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            .snip1390 {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
Home-blog
--------------------------------------------------------------*/
    .home-blog {

        .home-blog-body {
            flex-wrap: wrap;

            .home-blog-card {
                width: 100%;
                margin-top: 5%;

                .home-blog-card-text {
                    a {

                        font-size: 3.5vw;
                    }
                }

                h6 {
                    font-size: 3vw;
                    top: 21%;
                }

                img {
                    height: 80%;

                }
            }
        }
    }

    /*--------------------------------------------------------------
Certificates
--------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    margin: 3%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Smo
  --------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
        }
    }

    /*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: $xl) {

    .dropdown-menu {
        left: -250% !important;
        width: 650%;
        // height: 40vh;
    }

    .navdropdown-subheadings a {
        font-size: 1.3vw;
        margin: 0;
        padding-bottom: 1%;
    }


}

@media (min-width: 768) and (max-width: 966px) {

    .w {
        // left: 10%;
        width: 30%;
        // top: 50%;
        position: inherit;
        margin-left: 3%;
        margin-top: 5%;

        a {
            font-size: 2vw;
        }
    }
}