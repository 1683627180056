@import '../../Sass/variables.scss';
@import '../../Sass/mixins.scss';

/* ===================================================
  Blog
====================================================*/
.blog {
    margin-top: 10%;

    .react-player__preview {
        border: 1px solid #000;
    }

    .banner {
        width: 100%;
    }

    .blog-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        // flex-direction: row-reverse;
        align-items: stretch;
        margin: 5% auto;
        padding: 0 5%;

        .blog-content-body {
            width: 31%;
            margin: 1%;

            img {
                width: 100%;
                height: 60%;
                border: 1px solid $insur-black;
                object-fit: cover;
            }

            .blog-content-body-text {
                padding: 5% 0;

                h5 {
                    font-size: 1.5vw;
                    font-family: $blog-family;

                    a {
                        text-decoration: none;
                        color: inherit;

                        &:hover {
                            color: $insur-base;
                        }
                    }
                }

            }
        }
    }
}

/* ===================================================
  Blog Details
====================================================*/
.blog-details {
    h1 {
        color: rgba(0, 0, 0, .6);
        margin: 2% 0;
    }

    .blog-content {
        margin: 0;

        .blog-content-body {
            width: 70%;
            margin: 2% auto;

            img {
                height: 75vh;
                object-fit: cover;
            }

            .blog-details-content-body-text {
                img {
                    border: none;
                    float: right;
                }

                h1 {
                    text-align: center;
                    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                    font-weight: bold;
                    margin: 5% 0;
                    font-size: 3vw;
                    color: #000;
                }

                h2 {
                    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                    margin: 5% 0;
                    font-size: 2.5vw;
                    color: #000;
                    font-weight: bold;
                    text-align: left;
                }

                a {
                    text-decoration: none;
                }

                h3 {
                    margin: 5% 0 2% 0;
                    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                    font-size: 2.5vw;
                }

                p {
                    line-height: 2em;
                    font-size: 1.5vw;
                    font-family: var(--blog-family);

                    strong {
                        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                        // font-size: 2.5vw;
                    }

                }

                li {
                    list-style-type: disc;
                    font-size: 1.5vw;
                    line-height: 150%;
                    margin-bottom: 3%;
                }
            }
        }
    }

    .blog-content-alsoread {
        width: 80%;
        margin: 2% auto;
        border-top: 1px solid rgba(0, 0, 0, .2);
        padding: 1%;

        h3 {
            text-align: center;
            margin: 2% 0;
        }

        .blog-content-card {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;

            .blog-content-card-body {
                width: 30%;
                margin: 2%;
                border: 1px solid rgba(0, 0, 0, .1);
                padding: 1%;

                a {
                    text-decoration: none;
                    color: inherit;

                    h5 {
                        margin: 5% 0;
                        font-family: var(--blog-family);
                        font-size: 1.2vw;
                    }
                }

            }
        }
    }
}



/* ===================================================
   Media Queries
====================================================*/

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                img {
                    height: 100%;
                }

                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p,
                    li {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {


    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                img {
                    height: 100%;
                }

                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p,
                    li {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }


}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {


    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                img {
                    height: 100%;
                }

                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {}

@media (min-width: 1025px) and (max-width: $xl) {}

@media (min-width: 768) and (max-width: 966px) {}