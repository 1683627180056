@import url('https://fonts.googleapis.com/css2?family=Fauna+One&family=Kaushan+Script&family=Merriweather&family=Poppins&family=Ysabeau+Office&display=swap');

// colors
$insur-gray: #696e77;
$insur-gray-rgb: 105, 110, 119;
$insur-white: #ffffff;
$insur-white-rgb: 255, 255, 255;
$insur-base: #015fc9;
$insur-base-rgb: 1, 95, 201;
$insur-black: #16243d;
$insur-black-rgb: 22, 36, 61;
$insur-primary: #0ce0ff;
$insur-primary-rgb: 12, 224, 255;
$insur-extra: #f2f5f9;
$insur-extra-rgb: 242, 245, 249;
$insur-bdr-color: #e0e4e8;
$insur-bdr-color-rgb: 224, 228, 232;
$insur-bg: #939FC5;


// christmas color-palette
$red: #cc0022;
$green: #014f41;
$cream: #f4e7de;
$pink: #d29bc2;
$maroon: #990913;
$christmas-gradient: linear-gradient(90deg, rgba(1, 79, 65, 0.8801645658263305) 0%, rgba(153, 9, 19, 0.8717612044817927) 100%);
$christmas-trans: linear-gradient(90deg, rgba(1, 79, 65, 0.5) 0%, rgba(153, 9, 19, 0.5) 100%);

// republic day color-palette
$rorange: #ff9933;
$rblue: #000080;
$rgreen: #138808;
$republic-gradient: linear-gradient(90deg, rgba(255,153,51,1) 0%, rgba(19,136,8,1) 100%);
$republic-trans: linear-gradient(90deg, rgba(255,153,51,0.5) 0%, rgba(19,136,8,0.5) 100%);


// font-family
$text-family: 'Quintessential', cursive;
$cursive-family: 'Kaushan Script', cursive;
$subheading-family: 'Ysabeau Office', sans-serif;
$other-family: 'Fauna One', serif;
$blog-family: 'Merriweather', serif;
$main-family: 'Poppins', sans-serif;


// gradients
$background-gradient: linear-gradient(315deg, #0cbaba 0%, #263a74 74%);
$transparent-gradient: linear-gradient(315deg, rgba(12, 186, 186, .8) 0%, rgba(38, 58, 116, .8) 74%);
$title-gradient: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
$heading-gradient: linear-gradient(120deg, #255dd6 0%, #d43d14 100%);

// padding
$padding: 5% 5% 0 5%;
$margin: 5% 0;