.link-list {
  padding: 0;
}

.link-list-item {
  text-align: center;
  font-size: .9em;
}

.link-list-item-url {
  text-decoration: none;
  margin: 6px;
  display: block;
  color: #fff;
  background-color: #065555;
  padding: 8px;
  border-radius: 3px;
}