:root {
  --insur-gray: #696e77;
  --insur-gray-rgb: 105, 110, 119;
  --insur-white: #ffffff;
  --insur-white-rgb: 255, 255, 255;
  --insur-base: #015fc9;
  --insur-base-rgb: 1, 95, 201;
  --insur-black: #16243d;
  --insur-black-rgb: 22, 36, 61;
  --insur-primary: #0ce0ff;
  --insur-primary-rgb: 12, 224, 255;
  --insur-extra: #f2f5f9;
  --insur-extra-rgb: 242, 245, 249;
  --insur-bdr-color: #e0e4e8;
  --insur-bdr-color-rgb: 224, 228, 232;
  --insur-bg: #939FC5;
  --text-family: 'Quintessential', cursive;
  --cursive-family: 'Kaushan Script', cursive;
  --subheading-family: 'Ysabeau Office', sans-serif;
  --other-family: 'Fauna One', serif;
  --background-gradient: linear-gradient(315deg, #0cbaba 0%, #263a74 74%);
  --transparent-gradient: linear-gradient(315deg, rgba(12, 186, 186, .8) 0%, rgba(38, 58, 116, .8) 74%);
  --title-gradient: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  --heading-gradient: linear-gradient(120deg, #255dd6 0%, #d43d14 100%);
}

.learning-options-container,
.options-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.learning-option-button,
.option-button {
  padding: 0.5rem;
  border-radius: 5px;
  background: #263a74;
  border: none;
  margin: 1%;
  color: #fff;
}

.option-button{
  background: #58306b;
}