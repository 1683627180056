

.contact{
    margin-top: 7%;
    padding: 5%;
}




















