@import '../../Sass/variables.scss';
@import '../../Sass/mixins.scss';

/* ===================================================
   Careers
====================================================*/
.careers {
    margin: 0;

    img {
        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    .careers-section2 {
        padding: 0 3em;
        animation: 1s ease-out 0s 1 slideInLeft;

        .careers-card {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .ag-format-container {
                width: 1142px;
                margin: 0 auto;

                .ag-courses_box {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    padding: 50px 0;


                    .ag-courses_item {
                        flex-basis: calc(47.33333% - 30px);
                        margin: 0 15px 30px;
                        overflow: hidden;
                        border-radius: 28px;

                        &:nth-child(1) {
                            .ag-courses-item_bg {
                                background-color: #3ecd5e;
                            }
                        }

                        &:nth-child(2) {
                            .ag-courses-item_bg {
                                background-color: #e44002;
                            }
                        }

                        &:nth-child(3) {
                            .ag-courses-item_bg {
                                background-color: #952aff;
                            }
                        }

                        &:nth-child(4) {
                            .ag-courses-item_bg {
                                background-color: #94376e;
                            }
                        }
                        &:nth-child(5) {
                            .ag-courses-item_bg {
                                background-color: #3ecdcd;
                            }
                        }
                        &:nth-child(6) {
                            .ag-courses-item_bg {
                                background-color: #cdb03e;
                            }
                        }

                        &:hover {
                            .ag-courses-item_bg {
                                transform: scale(10);
                            }

                            .ag-courses-item_date {
                                a {
                                    color: #fff;
                                }
                            }
                        }
                    }

                    .ag-courses-item_link {
                        display: block;
                        padding: 30px 20px;
                        background-color: var(--insur-black);
                        overflow: hidden;
                        position: relative;
                        text-decoration: none;

                        .ag-courses-item_bg {
                            height: 128px;
                            width: 128px;
                            background-color: #f9b234;
                            z-index: 1;
                            position: absolute;
                            top: -75px;
                            right: -75px;
                            border-radius: 50%;
                            transition: all .5s ease;

                        }

                        .ag-courses-item_title {
                            min-height: 87px;
                            overflow: hidden;
                            font-weight: bold;
                            color: #FFF;
                            z-index: 2;
                            position: relative;
                            padding-right: 1em;
                            font-family: var(--text-family);
                        }

                        .ag-courses-item_date-box {
                            font-size: 18px;
                            color: #FFF;
                            z-index: 2;
                            position: relative;

                            .ag-courses-item_date {
                                a {
                                    font-weight: bold;
                                    color: #f9b234;
                                    transition: color .5s ease;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        p {
            font-family: var(--cursive-family);
            text-align: center;
            font-size: 2em;
        }

        h1 {
            background-image: var(--heading-gradient);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: var(--cursive-family);
            font-weight: bold;
            text-align: center;
            font-size: 3em;
            padding: 5% 0;
        }



    }

    .staffing-hiring {
        display: flex;

        img {
            width: 30%;
        }

        .staffing-hiring-text {
            margin: 0 5%;

            h2 {
                font-size: 2.8vw;
            }
        }

    }

    .careers-who {
        margin: 2em 0;
        padding: 0 3em;

        h1 {
            background-image: var(--heading-gradient);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: var(--cursive-family);
            font-weight: bold;
            text-align: center;
            font-size: 3em;
            padding: 5% 0;
        }

        .careers-sticky-notes {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: stretch;

            .quote-container {
                margin-top: 50px;
                position: relative;
                width: 33%;
                display: flex;
                align-items: stretch;

                .pin {
                    background-color: #aaa;
                    display: block;
                    height: 20px;
                    width: 2px;
                    position: absolute;
                    left: 50%;
                    top: 0px;
                    z-index: 1;

                    &:after {
                        background-color: #A31;
                        border-radius: 50%;
                        box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, .1), inset 3px 3px 3px hsla(0, 0%, 100%, .2), inset -3px -3px 3px hsla(0, 0%, 0%, .2), 23px 20px 3px hsla(0, 0%, 0%, .15);
                        content: '';
                        height: 12px;
                        left: -5px;
                        position: absolute;
                        top: -10px;
                        width: 12px;
                    }

                    &:before {
                        background-color: hsla(0, 0%, 0%, 0.1);
                        box-shadow: 0 0 .25em hsla(0, 0%, 0%, .1);
                        content: '';
                        height: 24px;
                        width: 2px;
                        left: 0;
                        position: absolute;
                        top: 8px;
                        transform: rotate(57.5deg);
                        transform-origin: 50% 100%;
                    }

                }

                .note {
                    color: #fff;
                    position: relative;
                    margin: 5%;
                    padding: 10%;
                    font-family: Satisfy;
                    font-size: 1.2em;
                    box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.3);
                    background: $background-gradient;
                    transform: rotate(-2deg);
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 979px) {
    .ag-courses_item {
        -ms-flex-preferred-size: calc(50% - 30px);
        flex-basis: calc(50% - 30px);
    }

    .ag-courses-item_title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .ag-format-container {
        width: 96%;
    }

}

@media only screen and (max-width: 639px) {
    .ag-courses_item {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }

    .ag-courses-item_title {
        min-height: 72px;
        line-height: 1;

        font-size: 24px;
    }

    .ag-courses-item_link {
        padding: 22px 40px;
    }

    .ag-courses-item_date-box {
        font-size: 16px;
    }
}



/* ===================================================
   Media Queries
====================================================*/

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .bot-shape {
        width: 40%;
        right: -5%;
    }

    .section-head .title-shape {
        width: 25% !important;
    }

    .w {
        // left: 10%;
        width: 50%;
        // top: 50%;
        position: inherit;
        margin-left: 5%;
        margin-top: 5%;

        a {
            font-size: 3vw;
        }
    }

    /*--------------------------------------------------------------
     Offer
  --------------------------------------------------------------*/
    .offer {
        height: 28%;
        top: 35%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
     Technologies
  --------------------------------------------------------------*/
    .technologies {
        .carousel {
            .carousel-wrapper {
                .carousel-body {
                    margin-bottom: 14%;
                }
            }

            .control-dots {
                .dot {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Digital
  --------------------------------------------------------------*/
    #digital {
        .digital-what {
            .digital-what-content {
                flex-wrap: wrap;
                padding: 5%;

                .digital-what-img {
                    width: 100%;
                    margin: 2%;

                    img {
                        border: none;
                    }
                }

                .digital-what-text {
                    width: 100%;

                    p {
                        font-size: 3.5vw;
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .digital-advantages {
            .digital-advantages-content {
                margin-top: 5%;

                .digital-advantages-content-img {
                    width: calc(100% / 2.3);
                    margin-bottom: 10%;

                    h2 {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .services-section {
        .services-content {
            margin: 5%;
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: 100%;
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 70vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 10vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 10vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Our Projects
  --------------------------------------------------------------*/

    .our-projects {

        .o-p-body {
            flex-direction: column;

            .o-p-left {
                width: 100%;
                margin-bottom: 5%;

                h5 {
                    font-size: 4vw;
                }

                .o-p-left-content {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: 100%;
                    padding: 0;

                    .o-p-card {
                        width: calc(100% / 2.1);
                        padding: .5%;
                        margin: 1% 1%;

                        img {
                            width: 30px;
                        }

                        h4 {
                            font-size: 2vw;
                        }
                    }
                }


            }

            .o-p-right {
                width: 95%;

                h5 {
                    font-size: 3vw;

                    a {
                        font-size: 2vw;
                    }
                }

                .o-p-right-card {
                    img {
                        height: 40vh;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Faq
  --------------------------------------------------------------*/
    .faq {
        .faq-body {
            padding: 1%;
            flex-wrap: wrap;

            .faq-accordion,
            .faq-form {
                width: 100%;
            }

            .faq-form {
                margin: 0;

                h2 {
                    font-size: 5vw;
                }

                h4 {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 50%;

                }
            }
        }

        .why-website {
            margin-top: 10%;

            .web-body-title {
                p {
                    margin-top: 5%;
                    font-size: 2vw;
                    width: 90%;
                }
            }

            .why-website-content {
                div {
                    width: 100%;

                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .web-body-title {
            p {
                font-size: 2vw;
                width: 90%;
            }
        }

        .web-why {
            .card {
                width: 90%;
            }

            .card-title {
                font-size: 4vw;
            }

            .card-subtitle {
                font-size: 3vw;
            }
        }

        .types-website {
            .types-website-content {
                .text {
                    font-size: 10vw;
                }

                .container {
                    width: 100%;
                }

                .overlay {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                }
            }
        }

        .web-process {
            padding: 0 1%;

            .web-process-img {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.6vw;
                }
            }

            .container {
                .pullUp {
                    padding: .3em;
                    border-radius: 0;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Bulk Marketing
  --------------------------------------------------------------*/

    .bulk-services {
        .web-why-card {
            .card {
                width: 90%;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;

            &:before {
                width: 80px;
            }
        }
    }

    .banner-hero {
        margin-top: 12%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 6vw;
    }

    /*--------------------------------------------------------------
    Header
  --------------------------------------------------------------*/
    .logo {
        max-width: 200%;
    }

    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: 2.5vw;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1em;
        letter-spacing: 1px;
    }

    .dropdown-menu {
        width: 110%;
    }

    .navdropdown-subheadings img {
        width: 20px;
    }

    /*--------------------------------------------------------------
    Slider
  --------------------------------------------------------------*/
    .slider {

        .carousel {
            width: 100%;
            // height: 25vh;
            flex-direction: column;

            .carousel-caption {
                top: 37%;

                p {
                    font-size: 2vw;
                    width: 100%;
                }
            }

            .carousel-wrapper {
                width: 100%;
            }
        }

        .slider-form {
            width: 100%;
            height: 100%;
            padding: 5%;

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
     About
  --------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    .about_us {
        margin-top: 10%;

        .about_us-content {
            flex-direction: column;

            img {
                width: 100%;
            }

            .abt-text {
                width: 100%;
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .vision {
            flex-direction: column;

            .vision-content {
                margin: 5% 0;
                padding: 5%;

                h4 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
     Blog
  --------------------------------------------------------------*/
    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Why Choose Us
  --------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 5vw;
                    top: 10%;
                }

                p {
                    margin-top: 25%;
                    font-size: 2.8vw;
                    padding: 0 3%;
                    text-align: right;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Services
  --------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
    Team
  --------------------------------------------------------------*/
    .leaders {
        .team-card {
            .team-card-img {
                img {
                    width: 100%;
                    height: 200px;
                }
            }

            svg {
                width: 30px;
                height: 30px;
            }

            .team-card-caption {
                h4 {
                    font-size: 3.4vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2.5vw;
                }
            }
        }
    }

    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 130px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 130px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2.5vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Testimonials
  --------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            .carousel {
                .carousel-wrapper {
                    .carousel-body {
                        flex-direction: column;
                        padding: 0 10%;
                        margin-bottom: 15%;
                    }
                }
            }

            figcaption {
                width: 100%;
                margin-top: 10%;

                .snip-img {
                    .profile {
                        width: 70px;
                        height: 70px;
                        top: -5%;
                        border: 3px solid $insur-black;
                    }
                }

                .snip {
                    height: 28vh;

                    &:after {
                        height: 80px;
                    }

                    .s {
                        .snip-text {
                            font-size: 2.2vw;

                            button {
                                font-size: 2vw;
                            }
                        }
                    }

                    .snip-child {
                        h4 {
                            font-size: 2.3vw;
                        }

                        h6 {
                            font-size: 1.5vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Home-blog
  --------------------------------------------------------------*/
    .home-blog {

        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            min-width: 100% !important;
        }

        #desktop {
            display: none;
        }

        #mobile {
            display: block;
        }



        .home-blog-card {
            height: 100%;

            .home-blog-card-text {
                position: relative;
                padding: 10% 5%;

                a {
                    font-size: 3.2vw;
                    line-height: 130%;
                    position: absolute;
                    top: -10%;
                    width: 100%;
                    left: 1%;
                    padding: 5%;
                }
            }

            h6 {
                font-size: 3vw;
            }

            img {
                height: 80%;

            }
        }
    }

    /*--------------------------------------------------------------
    Certificates
  --------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    margin: 3%;
                    width: 100%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Career
  --------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }

            .careers-card {
                .ag-format-container {
                    .ag-courses_box {
                        padding: 0;

                        .ag-courses_item {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 90%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 100%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Smo
  --------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
            border-radius: 10px;
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            flex-direction: column;

            .card {
                width: 95%;
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {
        img {
            width: 100%;
        }

        .web-why-card {
            .card {
                width: 90%;
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 6vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Web Solutions
  --------------------------------------------------------------*/
    .web-solutions {
        .smo-what-content {
            flex-direction: column;
        }

        .smo-what {
            .smo-what-content-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .solutions-feature {
            .solutions-feature-body {
                .solutions-feature-card {
                    width: 100%;
                    margin-bottom: 5%;

                    .caption {
                        h3 {
                            font-size: 4vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Footer
  --------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-2 {
                ul {
                    &:nth-child(3) {
                        flex-wrap: wrap;

                        li {
                            width: 30px;
                            height: 30px;
                            margin-right: 4%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    .c-snow,
    .c-snow2,
    .c-snow3,
    .c-snow4 {
        top: 10%;
    }

    .c-snowflake {
        width: 20%;
    }

    .bot-shape {
        width: 40%;
        right: -5%;
    }

    .section-head .title-shape {
        width: 20% !important;
        left: 40%;
        top: 120%;
    }

    .slider {
        .carousel {
            .carousel-caption {
                width: 80%;
                left: 45%;
            }
        }
    }

    .w {
        // left: 10%;
        width: 40%;
        // top: 50%;
        position: inherit;
        margin-left: 5%;
        margin-top: 5%;

        a {
            font-size: 2vw;
        }
    }

    /*--------------------------------------------------------------
     Offer
  --------------------------------------------------------------*/
    .offer {
        height: 28%;
        top: 35%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
   Digital
  --------------------------------------------------------------*/
    #digital {
        .digital-what {
            .digital-what-content {
                flex-wrap: wrap;
                padding: 5%;

                .digital-what-img {
                    width: 100%;
                    margin: 2%;

                    img {
                        border: none;
                    }
                }

                .digital-what-text {
                    width: 100%;

                    p {
                        font-size: 3.5vw;
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .digital-advantages {
            .digital-advantages-content {
                margin-top: 5%;

                .digital-advantages-content-img {
                    width: calc(100% / 2.3);
                    margin-bottom: 10%;

                    h2 {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .services-section {
        .services-content {
            margin: 5%;
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: 100%;
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 70vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 10vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 10vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Our Projects
  --------------------------------------------------------------*/
    .our-projects {

        .o-p-body {
            flex-direction: column;

            .o-p-left {
                width: 100%;
                margin-bottom: 5%;

                h5 {
                    font-size: 4vw;
                }

                .o-p-left-content {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: 100%;
                    padding: 0;

                    .o-p-card {
                        width: calc(100% / 2.1);
                        padding: .5%;
                        margin: 1% 1%;

                        img {
                            width: 30px;
                        }

                        h4 {
                            font-size: 2vw;
                        }

                    }
                }


            }

            .o-p-right {
                width: 95%;

                h5 {
                    font-size: 3vw;

                    a {
                        font-size: 2vw;
                    }
                }

                .o-p-right-card {
                    img {
                        height: 50vh;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Faq
--------------------------------------------------------------*/
    .faq {
        .faq-body {
            padding: 1%;
            flex-wrap: wrap;

            .faq-accordion,
            .faq-form {
                width: 100%;
            }

            .faq-form {
                margin: 0;

                h2 {
                    font-size: 5vw;
                }

                h4 {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Web
--------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

        .why-website {
            margin-top: 10%;

            .web-body-title {
                p {
                    margin-top: 5%;
                    font-size: 2vw;
                    width: 90%;
                }
            }

            .why-website-content {
                div {

                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .web-body-title {
            p {
                font-size: 2vw;
                width: 90%;
            }
        }

        .web-why {
            .card {
                width: 90%;
            }

            .card-title {
                font-size: 4vw;
            }

            .card-subtitle {
                font-size: 3vw;
            }
        }

        .types-website {
            .types-website-content {
                .text {
                    font-size: 10vw;
                }

                .container {
                    width: 100%;
                }

                .overlay {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                }
            }
        }

        .web-process {
            padding: 0 1%;

            .web-process-img {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.6vw;
                }
            }

            .container {
                .pullUp {
                    padding: .3em;
                    border-radius: 0;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Bulk marketing
--------------------------------------------------------------*/

    .bulk-services {
        .web-why-card {
            .card {
                width: 90%;

                .card-title {
                    font-size: 4vw;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Content marketing
--------------------------------------------------------------*/
    .content-services {
        img {
            width: 100%;
        }

        .web-why-card {
            .card {
                width: 90%;
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 5vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }


    /*--------------------------------------------------------------
Common
--------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }

    .banner-hero {
        margin-top: 8%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 6vw;
    }

    /*--------------------------------------------------------------
Navbar
--------------------------------------------------------------*/
    .logo {
        max-width: 200%;
    }

    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: .9em;
        font-weight: 600 !important;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1.2em;
        letter-spacing: 1px;
        padding: 10% 5%;
    }

    .dropdown-menu {
        width: 110%;
    }


    /*--------------------------------------------------------------
Slider
--------------------------------------------------------------*/
    .slider {

        .carousel {
            width: 100%;
            // height: 25vh;
            flex-direction: column;

            .carousel-wrapper {
                width: 100%;
            }

            .carousel-caption {
                top: 35%;

                h1 {
                    font-size: 8vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }

        .slider-form {
            width: 100%;
            height: 100%;
            padding: 5%;

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
About
--------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    .about_us {
        margin-top: 10%;

        .about_us-content {
            flex-direction: column;

            img {
                width: 100%;
            }

            .abt-text {
                width: 100%;
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .vision {
            flex-direction: column;

            .vision-content {
                margin: 5% 0;
                padding: 5%;

                h4 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
 Blog
--------------------------------------------------------------*/
    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 5vw;
                    top: 10%;
                }

                p {
                    margin-top: 25%;
                    font-size: 2.8vw;
                    padding: 0 3%;
                    text-align: right;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Services
--------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
Team
--------------------------------------------------------------*/
    .leaders {
        .team-card {
            .team-card-img {
                img {
                    width: 100%;
                    height: 250px;
                }
            }

            svg {
                width: 30px;
                height: 30px;
            }

            .team-card-caption {
                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 130px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 130px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Testimonials
--------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            .carousel {
                .carousel-wrapper {
                    .carousel-body {
                        flex-direction: column;
                        padding: 0 10%;
                        margin-bottom: 15%;
                    }
                }
            }

            figcaption {
                width: 100%;
                margin-top: 10%;

                .snip-img {
                    .profile {
                        top: -5%;
                        left: 6%;
                        border: 3px solid $insur-black;
                    }
                }

                .snip {
                    height: 40vh;

                    &:after {
                        height: 120px;
                    }

                    .s {
                        .snip-text {
                            font-size: 2.2vw;

                            button {
                                font-size: 2vw;
                                display: none;
                            }
                        }
                    }

                    .snip-child {
                        h4 {
                            font-size: 2.6vw;
                        }

                        h6 {
                            font-size: 1.8vw;
                        }
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
Home-blog
--------------------------------------------------------------*/
    .home-blog {

        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            min-width: 100% !important;
        }

        #desktop {
            display: none;
        }

        #mobile {
            display: block;
        }



        .home-blog-card {
            height: 100%;

            .home-blog-card-text {
                position: relative;
                padding: 10% 5%;

                a {
                    font-size: 3.2vw;
                    line-height: 130%;
                    position: absolute;
                    top: -10%;
                    width: 100%;
                    left: 1%;
                    padding: 5%;
                }
            }

            h6 {
                font-size: 3vw;
            }

            img {
                height: 80%;

            }
        }
    }

    /*--------------------------------------------------------------
Certificates
--------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    width: 100%;
                    margin: 3%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Career
--------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }

            .careers-card {
                .ag-format-container {
                    .ag-courses_box {
                        padding: 0;

                        .ag-courses_item {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 90%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 100%;

                    .pin {
                        top: 5%;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Smo
--------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Seo
--------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
        }
    }

    /*--------------------------------------------------------------
PPC
--------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            flex-direction: column;

            .card {
                width: 95%;
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Web Solutions
  --------------------------------------------------------------*/
    .web-solutions {
        .smo-what-content {
            flex-direction: column;
        }

        .smo-what {
            .smo-what-content-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .solutions-feature {
            .solutions-feature-body {
                .solutions-feature-card {
                    width: 48%;
                    margin-bottom: 5%;

                    .caption {
                        h3 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    .c-snowflake {
        width: 20%;
    }

    .c-snow,
    .c-snow2,
    .c-snow3,
    .c-snow4 {
        top: 8%;
    }

    .bot-shape {
        width: 40%;
        right: -5%;
    }

    .section-head .title-shape {
        width: 20% !important;
        left: 40%;
        top: 120%;
    }

    .slider {
        .carousel {
            .carousel-caption {
                width: 80%;
                left: 45%;
            }
        }
    }

    .w {
        // left: 10%;
        width: 60%;
        // top: 50%;
        position: inherit;
        margin-left: 5%;
        margin-top: 5%;

        a {
            font-size: 3vw;
        }
    }

    /*--------------------------------------------------------------
     Offer
  --------------------------------------------------------------*/
    .offer {
        height: 28%;
        top: 35%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
   Digital
  --------------------------------------------------------------*/
    #digital {
        .digital-what {
            .digital-what-content {
                flex-wrap: wrap;
                padding: 5%;

                .digital-what-img {
                    width: 100%;
                    margin: 2%;

                    img {
                        border: none;
                    }
                }

                .digital-what-text {
                    width: 100%;

                    p {
                        font-size: 3.5vw;
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .digital-advantages {
            .digital-advantages-content {
                margin-top: 5%;

                .digital-advantages-content-img {
                    width: calc(100% / 2.3);
                    margin-bottom: 10%;

                    h2 {
                        font-size: 3vw;
                    }
                }
            }
        }
    }

    .services-section {
        .services-content {
            margin: 5%;
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: 100%;
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 70vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 10vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 10vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Our Projects
  --------------------------------------------------------------*/

    .our-projects {

        .o-p-body {
            flex-direction: column;

            .o-p-left {
                width: 100%;
                margin-bottom: 5%;

                h5 {
                    font-size: 4vw;
                }

                .o-p-left-content {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: 100%;
                    padding: 0;

                    .o-p-card {
                        width: calc(100% / 2.1);
                        padding: .5%;
                        margin: 1% 1%;

                        img {
                            width: 30px;
                        }

                        h4 {
                            font-size: 2vw;
                        }

                    }
                }


            }

            .o-p-right {
                width: 95%;

                h5 {
                    font-size: 3vw;

                    a {
                        font-size: 2vw;
                    }
                }

                .o-p-right-card {
                    img {
                        height: 50vh;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    Faq
  --------------------------------------------------------------*/
    .faq {
        .faq-body {
            padding: 1%;
            flex-wrap: wrap;

            .faq-accordion,
            .faq-form {
                width: 100%;
            }

            .faq-form {
                margin: 0;

                h2 {
                    font-size: 5vw;
                }

                h4 {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

        .why-website {
            margin-top: 10%;

            .web-body-title {
                p {
                    margin-top: 5%;
                    font-size: 2vw;
                    width: 90%;
                }
            }

            .why-website-content {
                div {

                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .web-body-title {
            p {
                font-size: 2vw;
                width: 90%;
            }
        }

        .web-why {
            .card {
                width: 90%;
            }

            .card-title {
                font-size: 4vw;
            }

            .card-subtitle {
                font-size: 3vw;
            }
        }

        .types-website {
            .types-website-content {
                .text {
                    font-size: 10vw;
                }

                .container {
                    width: 100%;
                }

                .overlay {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                }
            }
        }

        .web-process {
            padding: 0 1%;

            .web-process-img {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.6vw;
                }
            }

            .container {
                .pullUp {
                    padding: .3em;
                    border-radius: 0;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Bulk marketing
  --------------------------------------------------------------*/

    .bulk-services {
        .web-why-card {
            .card {
                width: 90%;

                .card-title {
                    font-size: 4vw;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {
        img {
            width: 100%;
        }

        .web-why-card {
            .card {
                width: 90%;
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 5vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 3vw;
                }
            }
        }
    }


    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }

    .banner-hero {
        margin-top: 8%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 6vw;
    }

    /*--------------------------------------------------------------
    Navbar
  --------------------------------------------------------------*/
    .logo {
        max-width: 200%;
    }

    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: .9em;
        font-weight: 600 !important;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1.2em;
        letter-spacing: 1px;
        padding: 10% 5%;
    }

    .dropdown-menu {
        width: 110%;
    }


    /*--------------------------------------------------------------
Slider
--------------------------------------------------------------*/
    .slider {

        .carousel {
            width: 100%;
            // height: 25vh;
            flex-direction: column;

            .carousel-wrapper {
                width: 100%;
            }

            .carousel-caption {
                top: 35%;

                h1 {
                    font-size: 8vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }

        .slider-form {
            width: 100%;
            height: 100%;
            padding: 5%;

            form {

                input,
                textarea,
                select {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
 About
--------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    .about_us {
        margin-top: 10%;

        .about_us-content {
            flex-direction: column;

            img {
                width: 100%;
            }

            .abt-text {
                width: 100%;
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .vision {
            flex-direction: column;

            .vision-content {
                margin: 5% 0;
                padding: 5%;

                h4 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
     Blog
  --------------------------------------------------------------*/
    .blog {
        margin-top: 15%;

        .blog-content {
            flex-direction: column;

            .blog-content-body {
                width: 100%;

                .blog-content-body-text {
                    h5 {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    .blog-details {
        .blog-content {
            .blog-content-body {
                .blog-details-content-body-text {
                    h2 {

                        font-size: 4.5vw;
                    }

                    h3 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }

        .blog-content-alsoread {
            width: 100%;

            .blog-content-card {
                .blog-content-card-body {
                    a {
                        h5 {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 5vw;
                    top: 10%;
                }

                p {
                    margin-top: 25%;
                    font-size: 2.8vw;
                    padding: 0 3%;
                    text-align: right;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Services
--------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
    Team
  --------------------------------------------------------------*/
    .leaders {
        .team-card {
            .team-card-img {
                img {
                    width: 100%;
                    height: 250px;
                }
            }

            svg {
                width: 30px;
                height: 30px;
            }

            .team-card-caption {
                h4 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 130px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 130px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Testimonials
--------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            .carousel {
                .carousel-wrapper {
                    .carousel-body {
                        flex-direction: column;
                        padding: 0 10%;
                        margin-bottom: 15%;
                    }
                }
            }

            figcaption {
                width: 100%;
                margin-top: 10%;

                .snip-img {
                    .profile {
                        top: -5%;
                        left: 6%;
                        border: 3px solid $insur-black;
                    }
                }

                .snip {
                    height: 40vh;

                    &:after {
                        height: 120px;
                    }

                    .s {
                        .snip-text {
                            font-size: 2.2vw;

                            button {
                                font-size: 2vw;
                                display: none;
                            }
                        }
                    }

                    .snip-child {
                        h4 {
                            font-size: 2.6vw;
                        }

                        h6 {
                            font-size: 1.8vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Home-blog
--------------------------------------------------------------*/
    .home-blog {

        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            min-width: 100% !important;
        }

        #desktop {
            display: none;
        }

        #mobile {
            display: block;
        }



        .home-blog-card {
            height: 100%;

            .home-blog-card-text {
                position: relative;
                padding: 10% 5%;

                a {
                    font-size: 3.2vw;
                    line-height: 130%;
                    position: absolute;
                    top: -10%;
                    width: 100%;
                    left: 1%;
                    padding: 5%;
                }
            }

            h6 {
                font-size: 3vw;
            }

            img {
                height: 80%;

            }
        }
    }

    /*--------------------------------------------------------------
Certificates
--------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    width: 100%;
                    margin: 3%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Career
  --------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }

            .careers-card {
                .ag-format-container {
                    .ag-courses_box {
                        padding: 0;

                        .ag-courses_item {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 90%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 100%;

                    .pin {
                        top: 5%;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Smo
  --------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            flex-direction: column;

            .card {
                width: 95%;
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Web Solutions
  --------------------------------------------------------------*/
    .web-solutions {
        .smo-what-content {
            flex-direction: column;
        }

        .smo-what {
            .smo-what-content-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;
                }
            }
        }

        .solutions-feature {
            .solutions-feature-body {
                .solutions-feature-card {
                    width: 100%;
                    margin-bottom: 5%;

                    .caption {
                        h3 {
                            font-size: 4vw;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-2 {
                ul {
                    &:nth-child(3) {
                        flex-wrap: wrap;

                        li {
                            width: 35px;
                            height: 35px;
                            margin-right: 2%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;

            }
        }
    }


}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {

    .c-snow,
    .c-snow2,
    .c-snow3,
    .c-snow4 {
        top: 8%;
    }


    .bot-shape {
        width: 25%;
        right: -5%;
    }

    .section-head .title-shape {
        width: 15% !important;
        left: 42%;
        top: 110%;
    }

    .slider {
        .carousel {
            .carousel-caption {
                width: 80%;
                left: 45%;
            }
        }
    }


    .our-projects {
        .o-p-body {
            .o-p-right {
                .o-p-right-card {
                    img {
                        height: 45vh;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 4%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

    }

    /*--------------------------------------------------------------
    Career
  --------------------------------------------------------------*/
    .careers {
        .careers-section2 {
            padding: 0 1%;

            h1 {

                font-size: 4vw;
                padding: 5% 0;
            }

            p {
                font-size: 3.5vw;
            }


        }

        .staffing-hiring {
            flex-wrap: wrap;

            img {
                width: 60%;
                margin: auto;
                border-radius: 0;
                box-shadow: none;
            }

            .staffing-hiring-text {
                margin-top: 5%;

                h2 {
                    font-size: 5vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .careers-who {
            .careers-sticky-notes {
                .quote-container {
                    width: 50%;

                    .pin {
                        top: 5%;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {

        .web-why-card {
            .card {
                margin: 1%;

                &:hover {
                    box-shadow: none;
                }

                .card-title {
                    font-size: 3vw;
                    margin-top: 0;
                }

                .card-subtitle {
                    font-size: 2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }

    .banner-hero {
        margin-top: 5%;

        .banner-hero-text {
            h1 {
                font-size: 4vw;
            }

            .content {
                h2 {
                    font-size: 3vw;
                }
            }
        }
    }

    .heading {

        font-size: 5vw;
    }

    /*--------------------------------------------------------------
    Navbar
  --------------------------------------------------------------*/
    .container-fluid {
        flex-wrap: nowrap !important;
    }

    .basic-nav-dropdown-body {
        flex-wrap: wrap;
    }

    .basic-nav-dropdown-body div:nth-child(2) {
        flex-grow: 1;
    }

    .basic-nav-dropdown-body .navdropdown-heading {
        width: 100%;
        padding: 0 1%;
        margin: 0;
    }

    .navdropdown-subheadings {
        margin-top: 1%;
    }

    .navdropdown-subheadings a {
        margin: .2em 0;
        padding: 0 5%;
        font-size: .9em;
        font-weight: 600 !important;
    }

    .navdropdown-heading .navdropdown-heading-head {
        font-size: 1.2em;
        letter-spacing: 1px;
    }

    .dropdown-menu {
        width: 110%;
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {

                p {
                    margin-top: 25%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Team
  --------------------------------------------------------------*/
    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 200px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 200px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                    font-weight: bold;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    .leaders {
        .team-card {
            .team-card-caption {
                h4 {
                    font-size: 3vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2.3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        .seo-key-content-text {
            margin: 1%;
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            .card {
                margin: 1% auto;

                .card-title {
                    font-size: 5vw;
                }

                .card-subtitle {
                    font-size: 4vw;
                }
            }
        }
    }


}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {


    .offer {
        height: 48%;
        top: 30%;

        .offer-left {
            background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1698061156/AHS%20Assured%20Services/Diwali%20Offers/Untitled_design_vxtswc.png");
            background-size: 100%;
        }

        .carousel {
            .carousel-body {
                flex-direction: column;

                .carousel-img,
                .carousel-text {
                    width: 100%;

                    .img {
                        width: 100%;
                    }
                }

                .carousel-text {
                    button {
                        font-size: 4vw;
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
   Staffing
  --------------------------------------------------------------*/
    .staffing {

        .staffing-body-services {
            .staffing-body-services-text {
                .card {
                    width: calc(100% / 2.2);
                }
            }
        }

        .staffing-why {
            .staffing-why-stick {
                height: 100vh;
                margin: 20% auto 10%;
            }

            .staffing-why-text {
                p {
                    font-size: 1.5vw;
                    height: 20vh;
                    padding: 4%;

                }
            }

            .staffing-why-img {
                img {
                    height: 20vh;
                }
            }

            .staffing-why-content {
                border-radius: 10px;
            }
        }
    }

    /*--------------------------------------------------------------
    Web
  --------------------------------------------------------------*/

    #web {
        .web-banner {
            .typewriter {
                margin: 2em 2em;
                top: 6%;
            }

            .bg2 {
                img {
                    top: 10%;
                    width: 55%;

                }
            }
        }

    }

    /*--------------------------------------------------------------
    Content marketing
  --------------------------------------------------------------*/
    .content-services {

        .web-why-card {
            .card {
                margin: 1%;

                .card-title {
                    font-size: 2.5vw;
                }

                .card-subtitle {
                    font-size: 1.8vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    PPC
  --------------------------------------------------------------*/
    .ppc {
        .web-why-card {
            .card {
                p {
                    font-size: 1.5vw;
                }
            }
        }
    }


    /*--------------------------------------------------------------
    Common
  --------------------------------------------------------------*/
    .section-head {

        h4,
        h1 {
            font-size: 6.5vw;
        }
    }


    /*--------------------------------------------------------------
Home About
--------------------------------------------------------------*/
    .home-about {
        .home-about-us {
            flex-wrap: wrap;

            .abt-pic,
            .abt-text {
                width: 100%;
            }

            .abt-text {
                margin-top: 5%;

                p {
                    font-size: 3vw;

                }
            }
        }
    }

    /*--------------------------------------------------------------
Why Choose Us
--------------------------------------------------------------*/
    .whychooseus {
        .whychooseus-content {
            .whychooseus-content-text {
                width: 100%;
                margin: 4% 0;

                h6 {
                    font-size: 3vw;
                    top: 10%;
                }

                p {
                    font-size: 2.4vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Services
--------------------------------------------------------------*/
    .services-section {
        .services-content {
            .services-content-text {
                width: 100%;

                h3 {
                    font-size: 6vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Team
--------------------------------------------------------------*/
    .employee {
        flex-wrap: wrap;

        .team-card {
            width: 200px;

            .team-card-img {
                img {
                    width: 100%;
                    height: 200px;
                }
            }

            svg {
                width: 20px;
                height: 20px;
            }

            .team-card-caption {
                h4 {
                    font-size: 2vw;
                    font-weight: bold;
                }

                p {
                    font-size: 1.5vw;
                }
            }
        }
    }

    .leaders {
        .team-card {
            .team-card-caption {
                h4 {
                    font-size: 3vw;
                    font-weight: bold;
                }

                p {
                    font-size: 2.3vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
Testimonials
--------------------------------------------------------------*/
    .testimonials {
        .testimonials-body {
            .snip1390 {
                width: 100%;
            }
        }
    }

    /*--------------------------------------------------------------
Home-blog
--------------------------------------------------------------*/
    .home-blog {

        .home-blog-body {
            flex-wrap: wrap;

            .home-blog-card {
                width: 100%;
                margin-top: 5%;

                .home-blog-card-text {
                    a {

                        font-size: 3.5vw;
                    }
                }

                h6 {
                    font-size: 3vw;
                    top: 21%;
                }

                img {
                    height: 80%;

                }
            }
        }
    }

    /*--------------------------------------------------------------
Certificates
--------------------------------------------------------------*/
    .certificates {
        .cer-body {
            .cer-body-img {
                flex-wrap: wrap;

                #Frames {
                    margin: 3%;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Smo
  --------------------------------------------------------------*/
    .smo-what {
        .smo-what-content {
            flex-direction: column-reverse;

            .smo-what-content-text {
                width: 100%;
            }

            .smo-what-content-img {
                width: 100%;
            }
        }
    }

    .smo-types {
        .smo-types-content {
            flex-wrap: wrap;

            .smo-types-content-text {
                width: calc(100% / 2.2);

                h2 {
                    font-size: 3vw;
                }

                p {
                    font-size: 2.2vw;
                }
            }
        }
    }

    /*--------------------------------------------------------------
    Seo
  --------------------------------------------------------------*/
    .seo-key-content {
        flex-direction: column;

        .seo-key-content-text {
            width: 90%;
            margin: 1% auto;
        }
    }

    /*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/
    .footer {
        padding: 5%;

        .footer-header {
            flex-direction: column;

            .footer-header-text,
            .footer-header-button {
                margin-bottom: 3%;
                width: 100%;
            }

            .footer-header-text {
                .subheading {
                    &:first-child {
                        font-size: 4vw;
                    }
                }
            }
        }

        .footer-body {
            flex-direction: column;

            .footer-body-1,
            .footer-body-2,
            .footer-body-3 {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@media (min-width: 1025px) and (max-width: $xl) {

    .dropdown-menu {
        left: -250% !important;
        width: 650%;
        // height: 40vh;
    }

    .navdropdown-subheadings a {
        font-size: 1.3vw;
        margin: 0;
        padding-bottom: 1%;
    }


}

@media (min-width: 768) and (max-width: 966px) {

    .w {
        // left: 10%;
        width: 30%;
        // top: 50%;
        position: inherit;
        margin-left: 3%;
        margin-top: 5%;

        a {
            font-size: 2vw;
        }
    }
}
