@import "../../Sass/variables.scss";

.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: .5% 14%;
    color: #fff;
    z-index: 1000;
    background: $background-gradient;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid $insur-black;

    button {
        background: $insur-black;
        color: #fff;
        padding: 2%;
        margin-left: auto;
        padding: .5% 3%;
        border-radius: 5px;
        border: none;
        box-shadow: 2px 2px 5px rgba($color: #fff, $alpha: 0.5);
        border: 2px solid transparent;

        &:hover {
            border: 2px solid $insur-black;
            background: transparent;
            transition: .5s all ease-in-out;
        }
    }

    p {
        margin: 0;

        a {
            color: #fff;
            font-weight: bold;
        }
    }
}

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $lg){
    .cookies {
        font-size: 10px;
        padding: 0.5% 16%;
        flex-direction: column;

        text-align: center;

        p {
            margin-bottom: 2%;
        }

        button {
            margin: auto;
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {
    .cookies {
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {}

@media (min-width: 1025px) and (max-width: $xl) {}

@media (min-width: 768) and (max-width: 966px) {}