@import "../../Sass/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');


.portfolio2 {
    background: rgb(0, 65, 139);
    background: linear-gradient(199deg, rgba(0, 65, 139, 1) 0%, rgba(109, 171, 179, 1) 52%, rgba(6, 41, 79, 1) 100%);
    margin-top: 5%;
    padding: 5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713178838/AHS%20Assured%20Services/103923_juzb5t.jpg");
    background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713179035/AHS%20Assured%20Services/996_zvmyvo.jpg");
    background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713179327/AHS%20Assured%20Services/2148178055_odzzq7.jpg");
    background-image: url("https://res.cloudinary.com/ddwk3hbiu/image/upload/v1713179423/AHS%20Assured%20Services/133046_kjslsh.jpg");

    .head {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5% 0 5% 0;

        z-index: 100;
        border-radius: 5px;
        padding-top: 1.5%;
        width: 100%;

        a {
            box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.4);
            border-radius: 5px;
            background: linear-gradient(120deg, #a7d8fd 0%, #ccc7e3 100%);
            color: #000;
            // text-shadow: 1px 1px 1px #000;
            font-weight: 600;
            text-decoration: none;
            font-size: 20px;
            text-transform: uppercase;
            font-family: "Titillium Web", sans-serif;
            text-align: center;
            margin-right: 4%;
            letter-spacing: 1px;
            padding: 1.5% 3%;

            // text-shadow: 0 0 10px #fff, 0 0 5px #fff, 0 0 10px #0ce0ff, 0 0 10px #0ce0ff;
            box-shadow: 2px 2px 5px $insur-black;
            border: 1px solid #fff;

            &:hover {
                transition: .5s all ease;
                text-shadow: 2px 2px 10px #fff;
            }
        }
    }

    .websites-body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 5%;
        overflow-y: scroll;
        height: 90vh;

        .websites-card {
            width: calc(100% / 3.2);
            margin: 1% 1% 10% 1%;
            box-shadow: 5px 5px 2px rgba($color: #000000, $alpha: 0.4);
            border-radius: 10px 10px 0 0;
            position: relative;
            border: 1px solid rgba($color: #fff, $alpha: 0.5);

            .websites-img {
                border-radius: 10px 10px 0 0;
                position: relative;

                &:hover {
                    transition: .5s all ease;

                    .button {
                        display: flex;
                        transition: .5s all ease;
                    }
                }

                .button {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.7);
                    font-size: 25px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    transition: .5s all ease;
                    animation: blur-in 1s 1;

                    a {
                        padding: 2% 15%;
                        border-radius: 10px;
                        border: none;
                        background: transparent;
                        color: #fff;
                        border: 2px solid #fff;

                    }
                }

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px 10px 0 0;
                    background: #fff;
                }

                .videoFrame {
                    width: 100% !important;
                    height: 75vh !important;
                    background: transparent;

                    video {
                        background: $insur-black;
                        width: 100% !important;
                    }
                }
            }

            .websites-text {
                padding: 5% 3%;
                position: absolute;
                bottom: -20%;
                background: $background-gradient;
                width: 100%;
                color: #fff;
                border-radius: 0 0 10px 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 5px 5px 2px rgba($color: #000000, $alpha: 0.4);

                .websites-text-logo {
                    width: 60px;
                    height: 60px;
                    background: #fff;
                    position: relative;
                    border-radius: 4px;

                    img {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 10%;

                    }
                }

                .websites-text-caption {
                    width: 85%;

                    h6 {
                        font-size: 16px;
                        text-transform: capitalize;
                        font-weight: bold;
                        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
                        margin-bottom: 5%;
                        text-align: right;
                    }

                    a {
                        background: #fff;
                        border: none;
                        font-size: 12px;
                        padding: 5px 5px;
                        width: 50%;
                        border-radius: 30px;
                        color: $insur-black;
                        // border: 2px solid transparent;
                        font-weight: 600;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;
                        box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.4);

                        svg {
                            fill: $insur-black;
                            width: 15px;
                            height: 15px;
                            margin-right: 5%;

                        }

                        &:hover {
                            transform: translateX(-10px) scale(1.2);
                            transition: .5s all ease;
                        }
                    }
                }

            }
        }

        .websites-videos {
            width: calc(100% / 4.4);

            .websites-img {
                position: relative;

            }
        }

    }

    #portfolio-logos{
        .websites-card{
          .websites-img{
           img{
            padding: 10%;
           }
          }
        }
    }
}

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    .portfolio2 {
        margin-top: 14%;


        .head {
            flex-wrap: wrap;

            a {
                // width: 80%;
                margin-bottom: 4%;
                font-size: 14px;
                margin-right: 10%;
            }

        }

        .websites-body {
            .websites-card {
                width: 100%;
                margin-bottom: 25%;
            }

        }
    }




}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .portfolio2 {
        margin-top: 10%;

        .head {
            flex-wrap: wrap;

            a {
                // width: 80%;
                margin-bottom: 4%;
                font-size: 14px;
                margin-right: 5%;
            }

        }

        .websites-body {
            .websites-card {
                width: calc(100% / 2.1);
                margin-bottom: 15%;

                .websites-text {
                    .websites-text-caption {
                        h6 {
                            font-size: 14px;
                        }

                        a {
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    .portfolio2 {
        margin-top: 12%;

        .head {
            flex-wrap: wrap;

            a {
                // width: 80%;
                margin-bottom: 4%;
                font-size: 14px;
                margin-right: 5%;
            }

        }

        .websites-body {
            .websites-card {
                width: 100%;
                margin-bottom: 15%;
            }

        }
    }

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {

    .portfolio2 {
        .head {
            flex-wrap: wrap;

        }

        .websites-body {
            .websites-card {
                width: calc(100% / 3.3);
                margin-bottom: 7%;

                .websites-text {
                    .websites-text-caption {
                        h6 {
                            font-size: 14px;
                        }

                        a {
                            width: 70%;
                        }
                    }
                }
            }
        }

    }

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {


    .portfolio2 {
        .head {
            flex-wrap: wrap;

        }

        .websites-body {
            .websites-card {
                width: calc(100% / 2.1);
                margin-bottom: 7%;

                .websites-text {
                    .websites-text-caption {
                        h6 {
                            font-size: 14px;
                        }

                        a {
                            width: 70%;
                        }
                    }
                }
            }
        }

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}